import {
  MvcRedirectKey,
  AppPermissions,
  NotificationType,
  ProductStatus,
  TaggingSource,
  ProductType,
  SecurityModalType,
  WebLogLevel,
  Notification,
  TaggingModel,
  FormPostKeyValue,
  SourceSystem,
  DataRegions,
} from 'shared';
import { utility, ServiceError, WebUtils } from 'utils';
import { UserState } from '../../redux/store/Reducers/user.reducer';
import { AnalyticsState } from '../../redux/store/Reducers/analytics.reducer';
import { TabContainerState } from '../../redux/store/Reducers/tabcontainer.reducer';
import { SET_ERROR } from '../../redux/store/Actions/error.actions';
import { ErrorState } from '../../redux/store/Reducers/error.reducer';
import {
  tagPayBillCallToAction,
  tagCancelledPolicyPayBillAction,
  tagNotificationCardPayBillUrl,
  tagviewBillUrl,
} from './TaggingHelper';
import { SET_MODAL } from '../../redux/store/Actions/modal.actions';
import { CommonHandlers } from './CommonHandlers';
import { ModalState } from '../../redux/store/Reducers/modal.reducer';
import { BillPayData } from 'shared/models/billPayModel';

export interface HandleViewClickArguments {
  notification: Notification;
  userState: UserState;
  taggingModel: TaggingModel;
  tabState: TabContainerState;
}

const redirectToViewBillBS = (
  notification,
  activeTab,
  clubCode: string,
  customerId?: string,
  membershipNumber?: string,
  config?: any,
  mode?: string
): void => {
  const url = config['InsuranceURLs']['billingSummaryAWSURL'];
  const region = sessionStorage.getItem('dataRegion');
  const policyNumber = notification.productId;

  if (region === DataRegions.B1) {
    const payload = {
      key: MvcRedirectKey.BILLING_SUMMARY_AWS,
      mode: utility.getInsuranceMode(notification.productType),
      clubCode,
      policyNumber,
      sourceApp: 'MyAccount',
      redirectMode: 'manual',
      policyType: notification.productType,
    };
    WebUtils.FormPostRedirect(payload, url, customerId, membershipNumber);
  } else {
    // capture the notification view bill external fields and make form post
    const extraFields: FormPostKeyValue[] = [];
    extraFields.push({ key: 'key', value: MvcRedirectKey.BILLING_SUMMARY_AWS });
    extraFields.push({ key: 'policyNumber', value: notification.productId });
    extraFields.push({ key: 'clubCode', value: clubCode });
    extraFields.push({ key: 'mode', value: mode });
    extraFields.push({ key: 'returnUrl', value: window.location.href });
    extraFields.push({ key: 'sourceApp', value: 'MyAccount' });
    extraFields.push({ key: 'policyType', value: notification.productType });

    WebUtils.FormPost(extraFields, url, customerId, membershipNumber);
  }
};

const redirectToViewBillMvc = (
  notification,
  activeTab,
  clubCode: string,
  customerId?: string,
  membershipNumber?: string,
  config?: any
): void => {
  // update view bill url with intcmp
  let viewBillUrlWithIntcmp = '';
  if (notification.productType) {
    const isPayTabSelected: boolean = parseInt(activeTab.id) === 2;
    viewBillUrlWithIntcmp = tagviewBillUrl(notification.viewBillUrl!, notification.productType, isPayTabSelected);
  }
  // capture the notification view bill external fields and make form post
  const extraFields: FormPostKeyValue[] = [];
  extraFields.push({ key: 'key', value: MvcRedirectKey.BILLING_SUMMARY });
  extraFields.push({ key: 'policynumber', value: notification.productId });
  extraFields.push({ key: 'clubcode', value: clubCode });
  WebUtils.FormPost(extraFields, viewBillUrlWithIntcmp || notification.viewBillUrl, customerId, membershipNumber);
};

const getMode = (notification: any, club: any, config: any) => {
  const bsConfig = config?.BillingSummaryAWSEnabled;
  let mode = utility.getInsuranceMode(notification.productType);
  let result = '';

  if (bsConfig[club]) {
    const clubConfig = bsConfig[club];
    Object.keys(clubConfig).forEach((k) => {
      if (!clubConfig[k]) return;
      if (k !== mode) return;

      const val = clubConfig[k];
      const prefixes = val.split(',');

      if (prefixes.includes(notification.productId.substring(0, 3))) {
        result = mode;
      }
    });
  }
  return result;
};

const redirectToViewBill = (
  notification,
  activeTab,
  clubCode: string,
  customerId?: string,
  membershipNumber?: string,
  config?: any
): void => {
  const mode = getMode(notification, clubCode, config);
  if (mode) {
    return redirectToViewBillBS(notification, activeTab, clubCode, customerId, membershipNumber, config, mode);
  } else {
    return redirectToViewBillMvc(notification, activeTab, clubCode, customerId, membershipNumber);
  }
};

const handleViewClick = async (dispatch, args: HandleViewClickArguments, config: any): Promise<void> => {
  const { sessionId, aceMeta } = WebUtils.getMetadata();
  const { notification, userState, tabState } = args;
  try {
    NotificationHandler.redirectToViewBill(
      notification,
      tabState.activeTab,
      userState.clubCode,
      userState.customerId,
      userState.membershipNumber,
      config
    );
  } catch (error: any) {
    WebUtils.logWeb({
      message: 'Failed view bill',
      level: WebLogLevel.error,
      data: WebUtils.constructLogData({ error }, userState?.customerId, userState?.membershipNumber),
      aceMeta,
    });
    const errorModel: ServiceError = {
      ErrorMessage: error.message,
      SessionId: sessionId,
      CustomerId: '',
      ComponentErrorCode: '',
      ComponentName: 'NotificationHandler',
      ComponentStack: [''],
      Severity: '',
    };
    const errorState: ErrorState = { model: errorModel };
    dispatch({ type: SET_ERROR, payload: errorState });
  }
};

const handleUrlClick = (dispatch, args) => {
  const { url, userState, config } = args;
  if (NotificationHandler.canManageMembership(userState) && url) {
    WebUtils.externalLinkHandler(url, false, false, userState?.customerId, userState?.membershipNumber).catch(() => { });
  } else {
    NotificationHandler.handleSecurityModal({ value: true, config }, dispatch);
  }
};

const handleCancelClick = (dispatch, args) => {
  const { notification, userState, config, tabState } = args;

  if (notification.productType === ProductType.MEMBERSHIP) {
    if (NotificationHandler.canManageMembership(userState) && notification.payBillUrl) {
      WebUtils.externalLinkHandler(
        notification.payBillUrl,
        false,
        false,
        userState?.customerId,
        userState?.membershipNumber
      ).catch(() => { });
    } else {
      NotificationHandler.handleSecurityModal({ value: true, type: SecurityModalType.MEMBERSHIP, config }, dispatch);
    }
  } else {
    if (notification.canSubmitPayment) {
      const mode = getMode(notification, userState.clubCode, config);
      if (mode) {
        const tab = tabState || {};
        redirectToViewBillBS(
          notification,
          tab.activeTab,
          userState.clubCode,
          userState.customerId,
          userState.membershipNumber,
          config,
          mode
        );
        return;
      }

      // form post to billing_summary app
      const extraFields: FormPostKeyValue[] = [];
      extraFields.push({ key: 'key', value: MvcRedirectKey.BILLING_SUMMARY });
      extraFields.push({ key: 'policynumber', value: notification.productId });
      extraFields.push({ key: 'clubcode', value: userState.clubCode });

      if (notification.cancelScheduledPaymentUrl) {
        WebUtils.FormPost(
          extraFields,
          notification.cancelScheduledPaymentUrl,
          userState?.customerId,
          userState?.membershipNumber
        );
      }
    } else {
      NotificationHandler.handleSecurityModal({ value: true, type: SecurityModalType.INSURANCE, config }, dispatch);
    }
  }
};

const handlePayClick = (dispatch, args) => {
  const { notification, config } = args;
  const url = config['InsuranceURLs']['billPayAWSRedirectURL'];

  if (url && (notification.sourceSystem === SourceSystem.HUON || notification.sourceSystem === SourceSystem.GW)) {
    NotificationHandler.handlePayClickBillPay(dispatch, args);
  } else {
    NotificationHandler.handlePayClickLegacy(dispatch, args);
  }
};

// Using New Bill Pay (AWS)
// to enable - set the URL in the config : InsuranceURLs.billPayAWSRedirectURL
const handlePayClickBillPay = (dispatch, args) => {
  const { notification, config, rootState } = args;
  const userState: UserState = rootState && rootState.userState;
  const modalState: ModalState = rootState && rootState.modalState;
  const analytics: AnalyticsState = rootState && rootState.analyticsState;
  const tabState: TabContainerState = rootState && rootState.tabContainerState!;
  const isPayBillsTabSelected = Boolean(tabState && tabState.activeTab && parseInt(tabState.activeTab.id) === 2);


  // update pay bill url with intcmp
  let { payBillUrl } = notification;

  const { productType } = notification;

  // TODO: ask whether we need this?
  if (payBillUrl && productType) {
    const ctaSource: string = isPayBillsTabSelected ? 'bills tab' : 'notification';
    payBillUrl = tagNotificationCardPayBillUrl(payBillUrl, ctaSource, 'pay bill', productType.toLowerCase());
  }

  // handle tagging
  if (analytics.model) {
    // update cta
    const { productType } = notification;
    analytics.model.cta = `my account:manage my account:${isPayBillsTabSelected ? 'module:' : ''
      }pay bills:${productType}:make a payment`.toLowerCase();
    tagPayBillCallToAction(analytics.model);

    // cancelled policy pay bill tagging
    if (notification.type === NotificationType.BILLING_REINSTATE) {
      analytics.model.policystate = ProductStatus.findByCode(notification.productStatus).taggingValue;
      tagCancelledPolicyPayBillAction(analytics.model, TaggingSource.NotificationCard);
    }
  }
  if (notification.productType === ProductType.MEMBERSHIP) {
    if (NotificationHandler.canManageMembership(userState) && payBillUrl) {
      WebUtils.externalLinkHandler(payBillUrl, false, false, userState?.customerId, userState?.membershipNumber).catch(
        () => { }
      );
    } else {
      NotificationHandler.handleSecurityModal(
        { value: true, type: SecurityModalType.MEMBERSHIP, config: config },
        dispatch
      );
    }
  } else {
    if (notification.productType === ProductType.INSURANCE_QUESTIONNAIRE) {
      if (notification.canSubmitPayment) {
        //for HUON IQ
        if (notification.externalFields && notification.externalFields.key) {
          //form post to policy view details
          const extraFields: FormPostKeyValue[] = [];
          const { externalFields } = notification;
          if (externalFields.key) {
            extraFields.push({ key: 'key', value: externalFields.key });
          }
          if (externalFields.policyNumber) {
            extraFields.push({ key: 'fullPolicyNumber', value: externalFields.policyNumber });
            extraFields.push({ key: 'policyNumber', value: externalFields.policyNumber });
          }
          if (externalFields.mode) {
            extraFields.push({ key: 'mode', value: externalFields.mode });
            extraFields.push({ key: 'policyType', value: externalFields.mode });
          }
          extraFields.push({ key: 'clubCode', value: userState.clubCode });
          payBillUrl && WebUtils.FormPost(extraFields, payBillUrl, userState?.customerId, userState?.membershipNumber);
        } else {
          payBillUrl &&
            WebUtils.externalLinkHandler(
              payBillUrl,
              false,
              false,
              userState?.customerId,
              userState?.membershipNumber
            ).catch(() => { });
        }
      } else {
        NotificationHandler.handleSecurityModal(
          { value: true, type: SecurityModalType.INSURANCE_QUESTIONNAIRE, config: config },
          dispatch
        );
      }
    } else {
      if (notification.canSubmitPayment && payBillUrl) {
        // use new bill pay URL only at this point
        payBillUrl = config['InsuranceURLs']['billPayAWSRedirectURL'];

        //window.location.assign(notification.payBillUrl);
        //capture the notification paybill external fields and make form post
        const extraFields: FormPostKeyValue[] = [];
        const { externalFields } = notification;

        if (externalFields) {
          extraFields.push({ key: 'referrer', value: 'myaccount' });

          let region = sessionStorage.getItem('dataRegion');
          if (region) {
            region = region.toUpperCase();
            extraFields.push({ key: 'testRegion', value: region });
          }

          if (notification.paymentData) {

            if (notification.paymentData.length > 1) {
              extraFields.push({ key: 'emailOnFile', value: externalFields.payBillFields.primaryEmail });
              extraFields.push({ key: 'firstName', value: userState.customerName });
              payBillUrl = config['InsuranceURLs']['billPayAWSRedirectURLGW'];
              extraFields.push({ key: 'policies', value: JSON.stringify(notification.paymentData,) });
            } else {
              extraFields.push({ key: 'policyNumber', value: externalFields.policyNumber });
              let payload: BillPayData = {
                ...notification.paymentData[0],
                emailOnFile: externalFields.payBillFields.primaryEmail,
                firstName: userState.customerName,
              };
              // NOTE: bill pay app does parse 2 times !!!
              extraFields.push({ key: 'data', value: JSON.stringify(JSON.stringify(payload)) });
            }
          } else {
            extraFields.push({ key: 'policyNumber', value: externalFields.policyNumber });
            // min due
            let sAmount = notification.amount || '';
            sAmount = sAmount.replaceAll(',', '');
            // can be empty, will be set to equal to min due in that case
            let scurrentBalance = notification.currentBalance || sAmount;
            scurrentBalance = scurrentBalance.replaceAll(',', '');
            let payload: BillPayData = {
              minimumDue: Number(sAmount),
              balanceRemaining: Number(scurrentBalance),
              minimumDueDate: notification.dateInternal,
              autoPay: {
                enrolled: notification.isAutoPay,
              },
              billPlan: notification.payPlanInternal,
              emailOnFile: externalFields.payBillFields.primaryEmail,
              firstName: userState.customerName,
              policyExpDate: notification.policyExpDate,
              // emailSent: --- used by bill pay team, don't provide
            };
            extraFields.push({ key: 'data', value: JSON.stringify(JSON.stringify(payload)) });
          }
        }

        // if BillingReinstatement, display additional modal... and delay the FormPost Call
        if (extraFields && notification.type === NotificationType.BILLING_REINSTATE) {
          CommonHandlers.handleReinstatablePolicyModal(notification.productId, TaggingSource.Blade, config!, {
            userState,
            analytics,
            modalState,
            dispatch,
            extraFields,
            payBillUrl,
          });
        } else if (extraFields) {
          WebUtils.FormPost(extraFields, payBillUrl);
        }
      } else {
        NotificationHandler.handleSecurityModal(
          { value: true, type: SecurityModalType.INSURANCE, config: config },
          dispatch
        );
      }
    }
  }
};

// Using Old Bill Pay functioinality (DEPRECATED)
const handlePayClickLegacy = (dispatch, args) => {
  const { notification, config, rootState } = args;
  const userState: UserState = rootState && rootState.userState;
  const modalState: ModalState = rootState && rootState.modalState;
  const analytics: AnalyticsState = rootState && rootState.analyticsState;
  const tabState: TabContainerState = rootState && rootState.tabContainerState!;
  const isPayBillsTabSelected = Boolean(tabState && tabState.activeTab && parseInt(tabState.activeTab.id) === 2);

  // update pay bill url with intcmp
  let { payBillUrl } = notification;
  const { productType } = notification;
  if (payBillUrl && productType) {
    const ctaSource: string = isPayBillsTabSelected ? 'bills tab' : 'notification';
    payBillUrl = tagNotificationCardPayBillUrl(payBillUrl, ctaSource, 'pay bill', productType.toLowerCase());
  }

  // handle tagging
  if (analytics.model) {
    // update cta
    const { productType } = notification;
    analytics.model.cta = `my account:manage my account:${isPayBillsTabSelected ? 'module:' : ''
      }pay bills:${productType}:make a payment`.toLowerCase();
    tagPayBillCallToAction(analytics.model);

    // cancelled policy pay bill tagging
    if (notification.type === NotificationType.BILLING_REINSTATE) {
      analytics.model.policystate = ProductStatus.findByCode(notification.productStatus).taggingValue;
      tagCancelledPolicyPayBillAction(analytics.model, TaggingSource.NotificationCard);
    }
  }
  if (notification.productType === ProductType.MEMBERSHIP) {
    if (NotificationHandler.canManageMembership(userState) && payBillUrl) {
      WebUtils.externalLinkHandler(payBillUrl, false, false, userState?.customerId, userState?.membershipNumber).catch(
        () => { }
      );
    } else {
      NotificationHandler.handleSecurityModal(
        { value: true, type: SecurityModalType.MEMBERSHIP, config: config },
        dispatch
      );
    }
  } else {
    if (notification.productType === ProductType.INSURANCE_QUESTIONNAIRE) {
      if (notification.canSubmitPayment) {
        //for HUON IQ
        if (notification.externalFields && notification.externalFields.key) {
          //form post to policy view details
          const extraFields: FormPostKeyValue[] = [];
          const { externalFields } = notification;
          if (externalFields.key) {
            extraFields.push({ key: 'key', value: externalFields.key });
          }
          if (externalFields.policyNumber) {
            extraFields.push({ key: 'fullPolicyNumber', value: externalFields.policyNumber });
          }
          if (externalFields.mode) {
            extraFields.push({ key: 'mode', value: externalFields.mode });
          }
          payBillUrl && WebUtils.FormPost(extraFields, payBillUrl, userState?.customerId, userState?.membershipNumber);
        } else {
          payBillUrl &&
            WebUtils.externalLinkHandler(
              payBillUrl,
              false,
              false,
              userState?.customerId,
              userState?.membershipNumber
            ).catch(() => { });
        }
      } else {
        NotificationHandler.handleSecurityModal(
          { value: true, type: SecurityModalType.INSURANCE_QUESTIONNAIRE, config: config },
          dispatch
        );
      }
    } else {
      if (notification.canSubmitPayment && payBillUrl) {
        //window.location.assign(notification.payBillUrl);
        //capture the notification paybill external fields and make form post
        const extraFields: FormPostKeyValue[] = [];
        const { externalFields } = notification;

        if (externalFields) {
          if (externalFields.key) {
            extraFields.push({ key: 'key', value: externalFields.key });
          }
          if (externalFields.policyNumber) {
            extraFields.push({ key: 'fullPolicyNumber', value: externalFields.policyNumber });
          }
          if (externalFields.mode) {
            extraFields.push({ key: 'mode', value: externalFields.mode });
          }
          if (externalFields.payBillFields) {
            extraFields.push({ key: 'primaryEmail', value: externalFields.payBillFields.primaryEmail });
            extraFields.push({ key: 'customerID', value: externalFields.payBillFields.customerID });
            extraFields.push({ key: 'memberNumber', value: externalFields.payBillFields.memberNumber });
            extraFields.push({ key: 'allowAutoPay', value: externalFields.payBillFields.allowAutoPay });
            extraFields.push({ key: 'allowPayment', value: externalFields.payBillFields.allowPayment });
            extraFields.push({ key: 'hasExistingSchedPmt', value: externalFields.payBillFields.hasExistingSchedPmt });
            extraFields.push({
              key: 'existingSchedPmtAmtString',
              value: externalFields.payBillFields.existingSchedPmtAmtString,
            });
            extraFields.push({
              key: 'existingSchedPmtDateString',
              value: externalFields.payBillFields.existingSchedPmtDateString,
            });
            extraFields.push({
              key: 'existingSchedPmtConfNbr',
              value: externalFields.payBillFields.existingSchedPmtConfNbr,
            });
            extraFields.push({ key: 'insMinDue', value: externalFields.payBillFields.insMinDue });
            extraFields.push({ key: 'policyRenewal', value: externalFields.payBillFields.policyRenewal });
            extraFields.push({ key: 'insCurrentTerm', value: externalFields.payBillFields.insCurrentTerm });
            extraFields.push({
              key: 'useSystemAgnosticCall',
              value: externalFields.payBillFields.useSystemAgnosticCall,
            });
            extraFields.push({
              key: 'cancelSchedPmtExecuted',
              value: externalFields.payBillFields.cancelSchedPmtExecuted,
            });
            extraFields.push({ key: 'hasLapseAccidents', value: externalFields.payBillFields.hasLapseAccidents });
            extraFields.push({ key: 'clientId', value: externalFields.payBillFields.clientId });
          }
        }

        // if BillingReinstatement, display additional modal... and delay the FormPost Call
        if (extraFields && notification.type === NotificationType.BILLING_REINSTATE) {
          CommonHandlers.handleReinstatablePolicyModal(notification.productId, TaggingSource.Blade, config!, {
            userState,
            analytics,
            modalState,
            dispatch,
            extraFields,
            payBillUrl,
          });
        } else if (extraFields) {
          WebUtils.FormPost(extraFields, payBillUrl);
        }
      } else {
        NotificationHandler.handleSecurityModal(
          { value: true, type: SecurityModalType.INSURANCE, config: config },
          dispatch
        );
      }
    }
  }
};

const canManageMembership = (userState: UserState) => {
  if (userState) {
    return userState.permissions.includes(AppPermissions.CAN_MANAGE_MEMBERSHIP);
  } else {
    return false;
  }
};

const handleSecurityModal = (args, dispatch?) => {
  //value, type?: SecurityModalType, primaryUser?: string, config?: ConfigResponseApp
  const { value, type, config } = args;
  if (type === SecurityModalType.MEMBERSHIP) {
    dispatch({
      type: SET_MODAL,
      payload: {
        securityModalDescription: config && config.Messages.ModalDialogMessages.MembershipFeaturesNoAccess.description,
        securityModalMessage: '', //'<Members> who have access to complete this include: <' + primaryUser + '>',
        securityModalType: SecurityModalType.MEMBERSHIP,
        securityModal: value,
      },
    });
  }
  if (type === SecurityModalType.INSURANCE) {
    dispatch({
      type: SET_MODAL,
      payload: {
        securityModalDescription: config && config.Messages.ModalDialogMessages.InsuranceFeaturesNoAccess.description,
        securityModalMessage: '', //'<Policyholders> who have access to complete this include: <' + primaryUser + '>',
        securityModalType: SecurityModalType.INSURANCE,
        securityModal: value,
      },
    });
  }
  if (type === SecurityModalType.INSURANCE_QUESTIONNAIRE) {
    dispatch({
      type: SET_MODAL,
      payload: {
        securityModalDescription:
          config && config.Messages.ModalDialogMessages.InsuranceQuestionaireNoAccess.description,
        securityModalMessage: '',
        securityModalType: SecurityModalType.INSURANCE,
        securityModal: value,
      },
    });
  }
};

export const NotificationHandler = {
  handleViewClick,
  handleUrlClick,
  handlePayClick,
  handlePayClickBillPay,
  handlePayClickLegacy,
  handleCancelClick,
  handleSecurityModal,
  redirectToViewBillMvc,
  redirectToViewBill,
  canManageMembership,
};
