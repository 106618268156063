import { GetSourceParam, SourceParam } from 'remotes';
import { AwarenessCard } from 'shared/models/awarenessCardModel';
import { Notification } from 'shared/models/notificationsModel';
import { CustomerDetails, CustomerDetailsProduct } from 'shared/models/customerDetailsModel';
import { ProductType } from 'shared/contracts/ProductType';

export interface ApiStatus {
  customer?: "rejected" | "fulfilled",
  membership?: "rejected" | "fulfilled",
  billing?: "rejected" | "fulfilled",
  policy?: "rejected" | "fulfilled",
}
export interface Customer {
  customerDetails: CustomerDetails;
  notifications: Notification[];
  awarenessCards: AwarenessCard[];
  products?: CustomerDetailsProduct[];
  raw?: any;
  apiStatus?: ApiStatus; 
  aceMeta?: any;
}

let email = '';

// Assumptions: customer should only have 1 product
// Products may not return any products and may not contain membership product
export const CustomerDetailsMapper = (): CustomerDetails => {
  const data: Customer = GetSourceParam(SourceParam.customerData);
  const productsData: Customer = GetSourceParam(SourceParam.productsData);
  let products = data.products ?? [];
  email = data.customerDetails.preferredEmailAddress.emailAddress;

  let allProducts: CustomerDetailsProduct[] = [];
  let memFlag = false;
  if (productsData) {
    productsData.products?.forEach((p) => {
      const product = products.find((p2) => p.productId === p2.productId);
      if (product) {
        memFlag = true;
        const completeProduct = { ...p, ...product };
        allProducts.push(completeProduct);
      } else {
        allProducts.push(p);
      }
    });

    const lifeProduct = products.find((p) => p.type === ProductType.LIFE);

    if (!memFlag) {
      allProducts.push(...products);
    } else if (lifeProduct) {
      allProducts.push(lifeProduct);
    }
  } else {
    allProducts = products;
  }
  const pmidProduct = products.find((p) => p.type === ProductType.PMID);

  if (pmidProduct && !(allProducts.find((p) => p.type === ProductType.PMID))) {
    allProducts.push(pmidProduct);
  }

  // sort the blades by rank
  const sorted = allProducts.sort((a, b) => {
    if (a && a.rank && b && b.rank) {
      return a.rank - b.rank;
    }
    return 0;
  })

  let cd: CustomerDetails = data.customerDetails;
  cd.products = sorted;
  return cd;
};

export const NotificationsMapper = (): Notification[] => {
  const data: Customer = GetSourceParam(SourceParam.customerData);
  let n: Notification[] = [];
  let nResult: Notification[] = [];

  n.push(...data.notifications);
  const productsData: Customer = GetSourceParam(SourceParam.productsData);
  if (productsData?.notifications) {
    n.push(...productsData.notifications);
  }

  // Membership notification update
  let memProduct = data.products?.find((x) => x.type === ProductType.MEMBERSHIP);
  let allowSubmitPayment = memProduct ? memProduct.allowSubmitPayment : false;

  let memNotif = n.find((x) => x.productType === ProductType.MEMBERSHIP);
  if (memNotif && data.products) {
    if (memProduct) {
      allowSubmitPayment = memProduct.allowSubmitPayment;
      if (allowSubmitPayment) {
        memNotif.title = memProduct.productName;
        memNotif.canSubmitPayment = allowSubmitPayment;
        nResult.push(memNotif);
      }
    }
  }

  // Bring in everything non-membership notifications
  let allowedNotif = n.filter((p) => {
    return p.productType !== ProductType.MEMBERSHIP;
  });
  nResult.push(...allowedNotif);

  nResult.forEach((notif) => {
    if (notif.externalFields) {
      if (notif.externalFields.payBillFields) {
        notif.externalFields.payBillFields.primaryEmail = email;
      }
    }
  })

  // Sorted by date and then by productType if needed
  nResult = nResult.sort((a, b) => {
    if (a.dateInternal && b.dateInternal) {
      const dateA: any = new Date(a.dateInternal);
      const dateB: any = new Date(b.dateInternal);
      const diff: number = dateA - dateB;
      if (diff > 0) return 1;
      if (diff < 0) return -1;
      const rankA = a.rank || 0;
      const rankB = b.rank || 0;
      return rankA > rankB ? 1 : -1;
    } else {
      if (a.dateInternal) return 1;
      if (b.dateInternal) return -1;
    }
    return 0;
  });

  return nResult;
};

export const AwarenessCardsMapper = (): AwarenessCard[] => {
  const data: Customer = GetSourceParam(SourceParam.customerData);
  let ac: AwarenessCard[] = [];
  ac.push(...data.awarenessCards);
  const productsData: Customer = GetSourceParam(SourceParam.productsData);
  if (productsData?.awarenessCards) {
    ac.push(...productsData.awarenessCards);
  }

  // sort cards by rank
  const result = ac.sort((a, b) => {
    if (a.rank !== b.rank) {
      return a.rank > b.rank ? 1 : -1;
    }
    return 0;
  })

  return result;
};
