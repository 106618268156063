import React from 'react';
import { connect } from 'react-redux';
import Redux, { bindActionCreators } from 'redux';
import {
  Blade,
  BladeContent,
  BladeIcon,
  BladeIconType,
  Modal,
  OverlayIconType,
  OverlayItem,
  SelectListOverlay,
  Spinner,
} from '@clublabs/ace-component-library';
import { BladeContent as BladeContentNew, BladeIconType as BladeIconTypeNew } from '@clublabs/acl-new';
import {
  tagRoadsideAssistanceCallToAction,
  tagOverlayOption,
  tagNonNamedInsuredModal,
  tagSafeliteBlade,
  tagSevenDayWait,
  tagPMID,
} from '../Common/TaggingHelper';
import {
  AutoPolicyPrefixes,
  MembershipOptionsResponseApp,
  ProductType,
  AppPermissions,
  CustomerDetails,
  MembershipOption,
  IconFlags,
  MembershipOptionsDisplays,
  ConfigResponseApp,
  InsuranceExternalFields,
  InsuranceOption,
  InsuranceOptionsResponseApp,
  CustomerDetailsProduct,
  SecurityModalType,
  TaggingSource,
  FormPostKeyValue,
  CardCodeEnum,
  WebLogLevel,
  InsuranceOptionsDisplays,
  MvcRedirectKey,
  ApiStatus,
  ApiRefIdSuffix,
  PRODUCT_TYPE,
  SourceSystem,
  TravelBookingResponseApp,
  TravelReservations,
  ClubCodes,
  DataRegions,
  MembershipType,
} from 'shared';
import { ServiceError, utility, Timer, WebUtils } from 'utils';
import { RootState } from '../../redux/store/rootReducer';
import { setErrorState } from '../../redux/store/Actions/error.actions';
import { ErrorState } from '../../redux/store/Reducers/error.reducer';
import { setModalState } from '../../redux/store/Actions/modal.actions';
import { CommonHandlers } from '../Common/CommonHandlers';
import CardsContainer from '../Common/CardsContainer';
import { ModalState } from '../../redux/store/Reducers/modal.reducer';
import { GetSourceParam, SourceParam, appSources } from 'remotes';
import { PolicyNumberHint } from 'Components/Common/PolicyNumberHint';
import { BillPayData } from 'shared/models/billPayModel';
import { UserState } from 'redux/store/Reducers/user.reducer';
import LogCannon from '@clublabs/log-cannon';
import { MembershipUpgradeHint } from 'Components/Common/MembershipUpgradeHint';
import { ClaimsHint } from 'Components/Common/ClaimsHint';
import { Customer } from 'shared/mappers/customerMapper';
import ErrorCard from 'Components/AwarenessCard/ErrorCard';

interface HomeState {
  showOptionsOverlayModal: boolean;
  overlayOptions: Array<MembershipOption | InsuranceOption>;
  product?: any;
  isFetching?: boolean;
  travelReservations: TravelReservations[];
}

interface RequiredProps {
  customerDetails: CustomerDetails;
  customerDetailsStatus: ApiStatus;
  config: ConfigResponseApp;
  showNotificationError?: boolean;
}
interface OptionalProps {
  classes?: any;
}

const mapStateToProps = (rootState: RootState) => {
  return {
    userState: rootState.userState,
    analytics: rootState.analyticsState,
    errors: rootState.errorState,
    modalState: rootState.modalState,
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return bindActionCreators(
    {
      setErrorState,
      setModalState,
    },
    dispatch
  );
};

export type HomeProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps &
  Partial<OptionalProps>;

class HomeTabContent extends React.Component<HomeProps, HomeState> {
  sources = appSources();
  isComponentMounted = false;

  constructor(props: HomeProps) {
    super(props);
    this.state = {
      showOptionsOverlayModal: false,
      overlayOptions: [],
      isFetching: false,
      travelReservations: [],
    };
  }

  async componentDidMount() {
    const { membershipNumber, clubCode, customerId } = Object.assign(
      { membershipNumber: '', clubCode: '', customerId: '' },
      this.props.userState
    );
    const { sessionId, aceMeta } = WebUtils.getMetadata({
      customerId,
      membershipNumber,
      clubCode,
    });
    const tstCallTimer = new Timer('travelBookingWeb', sessionId);
    const tstCallStart = tstCallTimer.startTimer();
    this.isComponentMounted = true;
    try {
      const tstRequest = {
        aceMeta,
        membershipNumber,
      };
      LogCannon.blast('travelBooking - start', { aceMeta, start: tstCallStart.start });
      const tstResponse: TravelBookingResponseApp = await this.sources.travelBooking(tstRequest);
      const { end, responseTime } = tstCallTimer.endTimer();
      LogCannon.blast('travelBooking - end', {
        aceMeta,
        metrics: { start: tstCallStart.start, end, responseTime },
        status: 'success',
      });
      if (this.isComponentMounted) {
        this.setState({
          travelReservations: tstResponse.data.travelReservations,
        });
      }
    } catch (error) {
      const { end, responseTime } = tstCallTimer.endTimer();
      LogCannon.error('travelBooking - end', {
        aceMeta,
        metrics: { start: tstCallStart.start, end, responseTime },
        status: 'fail',
        error,
      });
    }
  }

  componentWillUnmount(): void {
    this.isComponentMounted = false;
  }

  public fetchMembershipOptions = (callback) => {
    this.setState({ isFetching: true }, () => {
      const { sessionId, aceMeta } = WebUtils.getMetadata();
      const request = { aceMeta };
      const membershipOptionsTimer = new Timer('membershipOptionsWeb', sessionId);
      const start = membershipOptionsTimer.startTimer();

      this.sources
        .membershipOptions(request)
        .then((response: MembershipOptionsResponseApp) => {
          WebUtils.logMetrics(
            'membershipOptionsWeb',
            WebUtils.constructLogData(
              { str: start, end: membershipOptionsTimer.endTimer(), status: 'success' },
              this.props.userState?.customerId,
              this.props.userState?.membershipNumber
            ),
            aceMeta
          );
          this.setState({
            isFetching: false,
          });
          callback(null, response.membershipOptions);
        })
        .catch((error: ServiceError) => {
          WebUtils.logMetrics(
            'membershipOptionsWeb',
            WebUtils.constructLogData(
              { str: start, end: membershipOptionsTimer.endTimer(), status: 'fail' },
              this.props.userState?.customerId,
              this.props.userState?.membershipNumber
            ),
            aceMeta,
            WebLogLevel.error
          );
          if (error) {
            error.SessionId = `${sessionId}-${ApiRefIdSuffix.MembershipOptions}`;
            if (this.props.setErrorState) this.props.setErrorState({ model: error } as ErrorState);
            WebUtils.logWeb({
              message: 'Failed to fetch membership options',
              level: WebLogLevel.error,
              data: WebUtils.constructLogData(
                { error },
                this.props.userState?.customerId,
                this.props.userState?.membershipNumber
              ),
              aceMeta,
            });
            this.setState({
              showOptionsOverlayModal: false,
              isFetching: false,
            });

            callback(error);
          }
        });
    });
  };

  public fetchInsuranceOptions = (product: CustomerDetailsProduct, callback: any) => {
    const { sessionId, aceMeta } = WebUtils.getMetadata();
    this.setState({ isFetching: true }, () => {
      const request = {
        aceMeta,
        policyNumber: product.productId,
        effectiveDate: product.productEffectiveDate,
      };
      const insuranceOptionsTimer = new Timer('insuranceOptionsWeb', sessionId);
      const start = insuranceOptionsTimer.startTimer();

      this.sources
        .insuranceOptions(request)
        .then((response: InsuranceOptionsResponseApp) => {
          WebUtils.logMetrics(
            'insuranceOptionsWeb',
            WebUtils.constructLogData(
              { str: start, end: insuranceOptionsTimer.endTimer(), status: 'success' },
              this.props.userState?.customerId,
              this.props.userState?.membershipNumber
            ),
            aceMeta
          );
          this.setState({
            isFetching: false,
          });
          callback(null, response.insuranceOptions);
        })
        .catch((error: ServiceError) => {
          WebUtils.logMetrics(
            'insuranceOptionsWeb',
            WebUtils.constructLogData(
              { str: start, end: insuranceOptionsTimer.endTimer(), status: 'fail' },
              this.props.userState?.customerId,
              this.props.userState?.membershipNumber
            ),
            aceMeta,
            WebLogLevel.error
          );
          if (error) {
            error.SessionId = `${sessionId}-${ApiRefIdSuffix.MembershipOptions}`;
            if (this.props.setErrorState) this.props.setErrorState({ model: error } as ErrorState);
            WebUtils.logWeb({
              message: 'Failed to fetch insurance options',
              level: WebLogLevel.error,
              data: WebUtils.constructLogData(
                { error },
                this.props.userState?.customerId,
                this.props.userState?.membershipNumber
              ),
              aceMeta,
            });
            this.setState({
              showOptionsOverlayModal: false,
              isFetching: false,
            });
            callback(error);
          }
        });
    });
  };

  public canManageMembership = () => {
    // TODO : need to update to use redux permisions in props ? ? ?
    if (this.props.customerDetails.user) {
      return this.props.customerDetails.user.permissions.includes(AppPermissions.CAN_MANAGE_MEMBERSHIP);
    } else {
      return false;
    }
  };

  public handleModal = (product: CustomerDetailsProduct, modalOpen: boolean) => {
    this.setState({ showOptionsOverlayModal: modalOpen });
    if (modalOpen) {
      this.setState({ product, overlayOptions: [] });
      switch (product.type) {
        case ProductType.MEMBERSHIP: {
          if (this.props.customerDetailsStatus === ApiStatus.SUCCESS) {
            this.fetchMembershipOptions((error, membershipOptions) => {
              if (!error) {
                this.setState({ overlayOptions: membershipOptions });
              }
            });
          }
          break;
        }
        default: {
          if (this.props.customerDetailsStatus === ApiStatus.SUCCESS) {
            this.fetchInsuranceOptions(product, (error, insuranceOptions) => {
              if (!error) {
                this.setState({ overlayOptions: insuranceOptions });
              }
            });
          }
          break;
        }
      }
    }
  };

  private buildPmidTitle = (productName: string) => {
    if (productName) {
      const category = productName.split(' ')[0];
      const trademark = <sup style={{ verticalAlign: 'super', fontSize: 'smaller' }}>®</sup>;
      return (
        <>
          ProtectMyID{trademark} {category}
        </>
      );
    }
    return '';
  };

  private createExtraFields = (
    product: CustomerDetailsProduct,
    userState: UserState,
    customerDetails: CustomerDetails
  ) => {
    const extraFields: any[] = [];
    extraFields.push({ key: 'referrer', value: 'myaccount' });
    extraFields.push({ key: 'policyNumber', value: product.productId });
    // min due
    let sAmount = product.billingSummary?.amount || '';
    sAmount = sAmount.replaceAll(',', '');
    // can be empty, will be set to equal to min due in that case
    let scurrentBalance = product.billingSummary?.totalBalance || sAmount;
    scurrentBalance = scurrentBalance.replaceAll(',', '');
    let payload: BillPayData = {
      minimumDue: Number(sAmount),
      balanceRemaining: Number(scurrentBalance),
      minimumDueDate: product.billingSummary?.billingDate ?? '',
      autoPay: {
        enrolled: product.hasAutoPay,
      },
      billPlan: product.billingSummary?.billPlan,
      emailOnFile: customerDetails.preferredEmailAddress.emailAddress,
      firstName: userState.customerName,
      policyExpDate:
        product.isPolicyReinstatable && product.reinstateDate ? product.reinstateDate : product.productExpiration,
      // emailSent: --- used by bill pay team, don't provide
    };
    // NOTE: bill pay app does parse 2 times !!!
    extraFields.push({ key: 'data', value: JSON.stringify(JSON.stringify(payload)) });
    return extraFields;
  };

  // cache for functions
  private handleBladeMap = {};

  public handleBladeClick = (product: CustomerDetailsProduct, customerDetails: CustomerDetails) => {
    const { config, userState } = this.props;
    const clubCode = userState?.clubCode ?? '';

    if (!this.handleBladeMap[product.productId]) {
      this.handleBladeMap[product.productId] = () => {
        const isMembershipOverlayEnabled = Boolean(
          WebUtils.isEnabledForClub(config?.MembershipOverlaysEnabled, clubCode)
        );
        const isInsuranceOverlayEnabled = Boolean(
          WebUtils.isEnabledForClub(config?.InsuranceOverlaysEnabled, clubCode)
        );

        if (product.type === ProductType.MEMBERSHIP) {
          if (isMembershipOverlayEnabled) {
            this.handleModal(product, true);
          } else {
            const manageMembershipURL = config?.MembershipURLs ? config?.MembershipURLs.manageMembershipURL : '';
            if (manageMembershipURL) {
              WebUtils.externalLinkHandler(
                manageMembershipURL,
                false,
                false,
                userState?.customerId,
                userState?.membershipNumber
              ).catch(() => {});
            }
          }
        } else {
          if (isInsuranceOverlayEnabled) {
            this.handleModal(product, true);
          } else if (product.type !== ProductType.LIFE) {
            // EN-2564
            if (product.isPolicyCancelled && product.isPolicyReinstatable) {
              const { userState, analytics, modalState, config, setModalState } = this.props;
              CommonHandlers.handleReinstatablePolicyModal(product.productId, TaggingSource.Blade, config!, {
                userState,
                analytics,
                modalState,
                setModalState,
                customerDetails,
                extraFields: this.createExtraFields(product, userState as any, customerDetails),
              });
            } else {
              this.handleBladeRedirection(product, clubCode as ClubCodes);
            }
          }
        }
      };
    }

    return this.handleBladeMap[product.productId];
  };

  private getRedirectKey = (product, config, clubCode) => {
    let redirectKey = MvcRedirectKey.POLICY_DETAILS;
    const awsEnabledConfig = config?.PolicyViewAWSEnabled;
    const awsEnabledClubs = Object.keys(awsEnabledConfig);

    if (awsEnabledClubs.find((club) => club === clubCode)) {
      const products = awsEnabledConfig[clubCode];
      const { type, sourceSystem } = product;

      if (
        products.find((p) => p === type) &&
        (sourceSystem === SourceSystem.HUON || sourceSystem.toUpperCase() === SourceSystem.GW)
      ) {
        // exclusions
        if (type === ProductType.AUTO && product.productId.startsWith(AutoPolicyPrefixes.CAClassicCar)) {
          return redirectKey;
        }
        redirectKey = MvcRedirectKey.POLICY_DETAILS_AWS;
      }
    }
    return redirectKey;
  };

  public handleBladeRedirection = (product: CustomerDetailsProduct, clubCode: ClubCodes) => {
    const { analytics, config } = this.props;
    const { aceMeta } = WebUtils.getMetadata();

    const page = this.getRedirectKey(product, config, clubCode);

    if (product.type === ProductType.PMID) {
      if (analytics) {
        tagPMID(analytics.model);
      }
      const experianURL = config?.PartnerURLs ? config?.PartnerURLs.experianURL : '';
      this.handlePmidLink(experianURL);
      return;
    }

    if (
      page === MvcRedirectKey.POLICY_DETAILS ||
      page === MvcRedirectKey.POLICY_DETAILS_AWS ||
      page === MvcRedirectKey.BILLING_SUMMARY // ??
    ) {
      if (!product.allowViewPolicy) {
        const message =
          this.props.config['Messages']['ModalDialogMessages']['InsuranceFeaturesNonNamed']['description'];
        this.props.setModalState &&
          this.props.setModalState({
            ...this.props.modalState,
            securityModalDescription: message,
            securityModalMessage: '',
            securityModalType: SecurityModalType.INSURANCE,
            securityModal: true,
          } as ModalState);
        if (analytics) {
          tagNonNamedInsuredModal(analytics.model);
        }
        return;
      }
    }

    const redirectUrls = {
      POLICY_DETAILS: config['InsuranceURLs']['policyDetailsRedirectURL'],
      POLICY_DETAILS_AWS: config['InsuranceURLs']['policyDetailsAWSRedirectURL'],
      BILLING_SUMMARY: config['InsuranceURLs']['billingSummaryRedirectURL'],
      ONLINE_BILL_PAY: config['InsuranceURLs']['onlineBillPayRedirectURL'],
    };

    WebUtils.logWeb({
      message: 'handleBladeRedirection',
      level: WebLogLevel.debug,
      data: WebUtils.constructLogData(
        {
          clubCode,
          page,
        },
        this.props.userState?.customerId,
        this.props.userState?.membershipNumber
      ),
      aceMeta,
    });

    const policyNumber = product.productId;
    const productType = product.type;
    const mode = utility.getInsuranceMode(product.type);
    const customerId = this.props.userState?.customerId;
    const membershipNumber = this.props.userState?.membershipNumber;

    if (page === MvcRedirectKey.POLICY_DETAILS_AWS) {
      const region = sessionStorage.getItem('dataRegion') ?? '';

      //TODO: ask ISS to whitelist ma2 url for CORS
      if (region === DataRegions.B1) {
        const payload = {
          policyNumber,
          policyType: productType,
          mode,
          clubCode,
          redirectMode: 'manual',
        };
        return WebUtils.FormPostRedirect(payload, redirectUrls[page], customerId, membershipNumber);
      } else {
        const formPostKeyValues: FormPostKeyValue[] = [
          { key: 'policyNumber', value: policyNumber },
          { key: 'policyType', value: productType },
          { key: 'mode', value: mode },
          { key: 'clubCode', value: clubCode },
        ];
        return WebUtils.FormPost(formPostKeyValues, redirectUrls[page], customerId, membershipNumber);
      }
    } else {
      const formPostKeyValues: FormPostKeyValue[] = [
        { key: 'key', value: page },
        { key: 'policynumber', value: policyNumber },
        { key: 'fullPolicyNumber', value: policyNumber },
        { key: 'mode', value: mode },
        { key: 'clubcode', value: clubCode },
      ];

      const productType = product.type.toLowerCase();
      return WebUtils.FormPost(
        formPostKeyValues,
        tagOverlayOption(redirectUrls[page], 'Blade', `${productType} clicked`, productType),
        customerId,
        membershipNumber
      );
    }
  };

  public handleOptionSelect = (item: OverlayItem) => {
    const product = this.state.product;

    // intcmp tagging for overlays
    if (item.display === InsuranceOptionsDisplays.VIEW_PAYMENT_AND_BILLING_HISTORY) {
      item.value = tagOverlayOption(item.value.toString(), 'overlay', 'view bill', product.type.toLowerCase());
    }

    //cancelled policy- pay bill
    if (
      item.display === MembershipOptionsDisplays.PAY_MY_BILL ||
      item.display === InsuranceOptionsDisplays.PAY_MY_BILL
    ) {
      item.value = tagOverlayOption(item.value.toString(), 'overlay', 'pay bill', product.type.toLowerCase());

      if (product.isPolicyCancelled && product.isPolicyReinstatable) {
        this.setState({ showOptionsOverlayModal: false });
        const { userState, analytics, modalState, config, setModalState } = this.props;
        CommonHandlers.handleReinstatablePolicyModal(product.productId, TaggingSource.Blade, config!, {
          userState,
          analytics,
          modalState,
          setModalState,
          customerDetails: this.props.customerDetails,
        });
        return true;
      }
    }

    if (item.extraFields && item.extraFields.length) {
      return WebUtils.FormPost(
        item.extraFields,
        `${item.value}`,
        this.props.userState?.customerId,
        this.props.userState?.membershipNumber
      );
    }

    // these option not restricted by role
    if (
      item.display === MembershipOptionsDisplays.PAY_MY_BILL ||
      item.display === MembershipOptionsDisplays.MANAGE_AUTOMATIC_PAYMENTS ||
      item.display === MembershipOptionsDisplays.ENROLL_AUTOMATIC_PAYMENTS ||
      item.display === MembershipOptionsDisplays.MANAGE_MEMBERSHIP
    ) {
      if (this.canManageMembership()) {
        return false;
      } else {
        // return 'true' to suppress the default behavior of opening a new url link.
        //NotificationHandler.handleSecurityModal({ value: true, type: SecurityModalType.MEMBERSHIP });
        this.props.setModalState &&
          this.props.setModalState({
            ...this.props.modalState,
            securityModalDescription:
              this.props.config &&
              this.props.config.Messages.ModalDialogMessages.MembershipFeaturesNoAccess.description,
            securityModalMessage: '', //'<Members> who have access to complete this include: <' + primaryUser + '>',
            securityModalType: SecurityModalType.MEMBERSHIP,
            securityModal: true,
          } as ModalState);
        //this.props.onSecurityError(true, SecurityModalType.MEMBERSHIP, '');
        return true;
      }
    } else {
      // append additional tagging value (if applicable)
      if (item.display === MembershipOptionsDisplays.DONT_SEE) {
        const cd = this.props.customerDetails;
        if (cd.emptyAuthorizations) {
          const productTypeEnum = PRODUCT_TYPE.findByDescription(this.state.product.type);
          const tracking = encodeURIComponent(productTypeEnum.taggingNoAuthValue);

          // move any hashtags (i.e. #faqs) to the end
          item.value = utility.insertInBetween(item.value.toString(), `?intcmp=${tracking}`, '#');
        }
      }
      return false;
    }
  };

  public mapFlagToIcon = (flag: IconFlags): OverlayIconType => {
    switch (flag) {
      case IconFlags.IS_PASTDUE:
        return OverlayIconType.HOUR_GLASS;
      default:
        return OverlayIconType.HOUR_GLASS;
    }
  };

  public mapExternalFields = (externalFields?: InsuranceExternalFields): FormPostKeyValue[] => {
    const extraFields: FormPostKeyValue[] = [];
    if (externalFields) {
      if (externalFields.key) {
        extraFields.push({ key: 'key', value: externalFields.key });
      }
      if (externalFields.policyNumber) {
        extraFields.push({
          key: 'policynumber',
          value: externalFields.policyNumber,
        });
        extraFields.push({
          key: 'fullPolicyNumber',
          value: externalFields.policyNumber,
        });
      }
      if (externalFields.mode) {
        extraFields.push({ key: 'mode', value: externalFields.mode });
      }
      if (externalFields.clubCode) {
        extraFields.push({ key: 'clubcode', value: externalFields.clubCode });
      }
      if (externalFields.payBillFields) {
        extraFields.push({
          key: 'primaryEmail',
          value: externalFields.payBillFields.primaryEmail,
        });
        extraFields.push({
          key: 'customerID',
          value: externalFields.payBillFields.customerID,
        });
        extraFields.push({
          key: 'memberNumber',
          value: externalFields.payBillFields.memberNumber,
        });
        extraFields.push({
          key: 'allowAutoPay',
          value: externalFields.payBillFields.allowAutoPay,
        });
        extraFields.push({
          key: 'allowPayment',
          value: externalFields.payBillFields.allowPayment,
        });
        extraFields.push({
          key: 'hasExistingSchedPmt',
          value: externalFields.payBillFields.hasExistingSchedPmt,
        });
        extraFields.push({
          key: 'existingSchedPmtAmtString',
          value: externalFields.payBillFields.existingSchedPmtAmtString,
        });
        extraFields.push({
          key: 'existingSchedPmtDateString',
          value: externalFields.payBillFields.existingSchedPmtDateString,
        });
        extraFields.push({
          key: 'existingSchedPmtConfNbr',
          value: externalFields.payBillFields.existingSchedPmtConfNbr,
        });
        extraFields.push({
          key: 'insMinDue',
          value: externalFields.payBillFields.insMinDue,
        });
        extraFields.push({
          key: 'policyRenewal',
          value: externalFields.payBillFields.policyRenewal,
        });
        extraFields.push({
          key: 'insCurrentTerm',
          value: externalFields.payBillFields.insCurrentTerm,
        });
        extraFields.push({
          key: 'useSystemAgnosticCall',
          value: externalFields.payBillFields.useSystemAgnosticCall,
        });
        extraFields.push({
          key: 'cancelSchedPmtExecuted',
          value: externalFields.payBillFields.cancelSchedPmtExecuted,
        });
        extraFields.push({
          key: 'hasLapseAccidents',
          value: externalFields.payBillFields.hasLapseAccidents,
        });
        extraFields.push({
          key: 'clientId',
          value: externalFields.payBillFields.clientId,
        });
      }
    }

    return extraFields;
  };

  public mapOptionsToOverlay = (option: MembershipOption | InsuranceOption): OverlayItem => {
    const { id, value, display, newWindow, subDisplay, subDisplayIndented, externalFields } = option;
    const extraFields = this.mapExternalFields(externalFields);
    return {
      id,
      value,
      display,
      newWindow,
      subDisplay,
      subDisplayIndented,
      icons: !option.flags ? [] : option.flags.map((flag) => this.mapFlagToIcon(flag)),
      extraFields,
    };
  };

  public getOverlayItems = (): OverlayItem[] => {
    const visibleItems = this.state.overlayOptions;
    return visibleItems.length > 0
      ? visibleItems.map((item) => this.mapOptionsToOverlay(item))
      : [
          {
            id: '1',
            value: '1',
            display: <React.Fragment>No items available.</React.Fragment>,
            newWindow: false,
            icons: [],
          },
        ];
  };

  private getProductRenewal = (product: CustomerDetailsProduct) => {
    return product && product.hasAutoPay && product.isPolicyReinstatable && product.reinstateDate ? (
      <span>
        <BladeIcon type={BladeIconType.RECURRING} />
        {`Auto Renew: ${product.reinstateDate} `}
      </span>
    ) : product && product.hasAutoPay ? (
      <span>
        <BladeIcon type={BladeIconType.RECURRING} />
        {`Auto Renew: ${product.productExpiration} `}
      </span>
    ) : product.isPolicyReinstatable && product.reinstateDate ? (
      `Renew: ${product.reinstateDate} `
    ) : product.productExpiration ? (
      `Renew: ${product.productExpiration} `
    ) : (
      ''
    );
  };

  private getMembershipBladeSubtitle = (membershipProduct: CustomerDetailsProduct): string => {
    const { productName, allowMakePolicyChanges, hasPIT } = membershipProduct;
    const membershipType: string = productName && productName.trim().split(' ')[0];
    const allowPolicyChangesAndNoPIT: boolean = allowMakePolicyChanges && !hasPIT;
    let subTitle = 'Manage your payments & membership';

    if (allowPolicyChangesAndNoPIT) {
      switch (membershipType.toLowerCase()) {
        case MembershipType.CLASSIC.toLowerCase():
          subTitle = 'Upgrade to Plus or Premier, or manage membership';
          break;
        case MembershipType.PLUS.toLowerCase():
          subTitle = 'Upgrade to Premier, or manage membership';
          break;
        case MembershipType.PREMIER.toLowerCase():
        default:
          break;
      }
    }
    return subTitle;
  };

  private getInsuranceBladeSubtitle = (product: CustomerDetailsProduct): string => {
    let subTitleAllInsProducts = 'Manage your payments and policy';
    const subTitleLifeProduct = 'Manage policy';

    const homeHuonPolicies = [
      ProductType.CONDO,
      ProductType.HOME,
      ProductType.RENTAL_DWELLING,
      ProductType.TENANT,
      ProductType.CALIFORNIA_EARTHQUAKE,
    ];

    const autoPolicies = [ProductType.AUTO];

    if (
      (product.sourceSystem === SourceSystem.HUON || product.sourceSystem?.toUpperCase() === SourceSystem.GW) &&
      (homeHuonPolicies.includes(product.type) || autoPolicies.includes(product.type))
    ) {
      subTitleAllInsProducts = 'Manage payments/policy or start claim';
    }

    return product.type !== ProductType.LIFE ? subTitleAllInsProducts : subTitleLifeProduct;
  };

  private getBladeHint = (product: CustomerDetailsProduct) => {
    if (product.type === ProductType.MEMBERSHIP) {
      return this.getMembershipBladeSubtitle(product);
    } else if (product.type === ProductType.PMID) {
      return 'See your credit report, build a protection plan, or get support';
    } else if (!this.canManageMembership()) {
      return 'Manage payments/policy or start claim';
    } else {
      return this.getInsuranceBladeSubtitle(product);
    }
  };

  public handleSevenDayWaitCTAClick = (membershipUpgradeUrl: string) => {
    const analytics = this.props.analytics;
    if (analytics) {
      tagSevenDayWait(analytics.model);
    }
    WebUtils.externalLinkHandler(
      membershipUpgradeUrl,
      true,
      true,
      this.props.userState?.customerId,
      this.props.userState?.membershipNumber
    ).catch(() => {});
  };

  public renderProductBlade = (
    product: CustomerDetailsProduct,
    index: number,
    customerDetails: CustomerDetails
  ): JSX.Element | null => {
    const productId: string =
      product.type === ProductType.MEMBERSHIP ? utility.formatMembership(product.productId) : product.productId;
    const productName: string = product.productName;
    const productExpirationOrRenewal: string | JSX.Element =
      product.type === ProductType.MEMBERSHIP
        ? `Valid through: ${product.productExpiration}`
        : this.getProductRenewal(product);

    let blandHint: string = this.getBladeHint(product);
    const productIdDesc: string | JSX.Element =
      product.productIdDesc && productId ? <span data-di-mask>{`${product.productIdDesc} : ${productId}`}</span> : '';
    let subTitle0: string | JSX.Element = blandHint;
    let subTitle1: string | JSX.Element = productIdDesc;
    let subTitle2: string | JSX.Element = productExpirationOrRenewal;
    let subTitle3: string | JSX.Element = '';

    let showTransitionHint = false;
    let bladeHintData: any = {};
    if (product.transitionProductId) {
      bladeHintData = {
        policyId: product.transitionProductId,
        effectiveDate: product.transitionEffectiveDate,
      };
      showTransitionHint = true;
    }

    const hasClaims: boolean = product.hasClaims || false;

    let showMembershipUpgradeHint = false;
    const pendingBenefitLevel = this.props.customerDetails?.membershipInfo?.pendingBenefitLevel;
    const isPendingBenefitLevelMessageEnabled = Boolean(
      this.props.config?.MembershipFeatureFlags?.Show7DayWaitMsg?.includes('ALL') ||
        this.props.config?.MembershipFeatureFlags?.Show7DayWaitMsg?.includes(this.props.userState?.clubCode)
    );

    if (product.type === ProductType.MEMBERSHIP && pendingBenefitLevel && isPendingBenefitLevelMessageEnabled) {
      showMembershipUpgradeHint = true;
      bladeHintData = {
        ...bladeHintData,
        pendingBenefitLevel: {
          membershipType: pendingBenefitLevel.membershipType,
          transitionEffectiveDate: pendingBenefitLevel.transitionEffectiveDate,
        },
      };
    }
    const preventClickHandler = (event) => {
      event.stopPropagation();
    };

    return product.type === ProductType.MEMBERSHIP ||
      (product.type !== ProductType.LIFE &&
        !WebUtils.isECNonPA(this.props.userState?.clubCode as string, this.props.userState?.state as string)) ? (
      <div>
        <Blade
          key={`${product.type}-${index}`}
          id={`${product.productId}`}
          onClick={this.handleBladeClick(product, customerDetails)}
        >
          {showMembershipUpgradeHint && (
            <div onClick={(e) => preventClickHandler(e)}>
              <MembershipUpgradeHint
                membershipType={bladeHintData?.pendingBenefitLevel?.membershipType}
                effectiveDate={bladeHintData?.pendingBenefitLevel?.transitionEffectiveDate}
                membershipUpgradeURL={this.props.config?.MembershipURLs.membershipUpgradeURL}
                sevenDayClickHandler={() => {
                  this.handleSevenDayWaitCTAClick(this.props.config?.MembershipURLs.membershipUpgradeURL);
                }}
              />
            </div>
          )}
          {showTransitionHint && (
            <PolicyNumberHint
              transitionEffectiveDate={bladeHintData?.effectiveDate}
              transitionProductId={bladeHintData?.policyId}
            />
          )}
          {hasClaims && <ClaimsHint />}
          <div
            style={{
              whiteSpace: 'break-spaces',
            }}
          >
            {product.type !== ProductType.PMID ? (
              <BladeContent
                id={`${product.productId}-content`}
                mainTitle={productName}
                subTitle0={subTitle0}
                subTitle1={subTitle1}
                subTitle2={subTitle2}
                subTitle3={subTitle3}
                icon={BladeIconType.RIGHT_ARROW_BOLD}
                leftIcon={BladeIconType[product.type]}
                keyboardAccessible={true}
                titleAccess={`Navigate to ${
                  product.type === ProductType.MEMBERSHIP ? 'membership details' : productName
                }`}
                onClick={this.handleBladeClick(product, customerDetails)}
              />
            ) : (
              <BladeContentNew
                id={`${product.productId}-content`}
                mainTitle={this.buildPmidTitle(productName)}
                subTitle0={subTitle0}
                subTitle1={subTitle1}
                subTitle2={subTitle2}
                subTitle3={subTitle3}
                icon={BladeIconType.RIGHT_ARROW_BOLD}
                leftIcon={BladeIconTypeNew[product.type]}
                keyboardAccessible={true}
                titleAccess={`Navigate to ${
                  product.type === ProductType.MEMBERSHIP ? 'membership details' : productName
                }`}
                onClick={this.handleBladeClick(product, customerDetails)}
              />
            )}
          </div>
        </Blade>
      </div>
    ) : null;
  };

  public handleExternalLink = (url: string) => {
    WebUtils.externalLinkHandler(
      url,
      false,
      true,
      this.props.userState?.customerId,
      this.props.userState?.membershipNumber
    ).catch(() => {});
  };

  public handlePmidLink = (url: string) => {
    WebUtils.externalLinkHandler(
      url,
      true,
      true,
      this.props.userState?.customerId,
      this.props.userState?.membershipNumber
    ).catch(() => {});
  };

  public handleRoadsideCTAClick = (roadsideUrl: string) => {
    const analytics = this.props.analytics;
    if (analytics) {
      tagRoadsideAssistanceCallToAction(analytics.model);
    }
    WebUtils.externalLinkHandler(
      roadsideUrl,
      true,
      true,
      this.props.userState?.customerId,
      this.props.userState?.membershipNumber
    ).catch(() => {});
  };

  public renderRoadSideBlade = (roadsideUrl: string) => {
    return (
      <Blade
        key="roadside"
        id="roadside-link"
        onClick={() => {
          this.handleRoadsideCTAClick(roadsideUrl);
        }}
      >
        <BladeContent
          id="roadside-content"
          mainTitle="Roadside Assistance"
          subTitle1="Get help on the road"
          subTitle2=""
          icon={BladeIconType.RIGHT_ARROW_BOLD}
          leftIcon={BladeIconType.ROADSIDE}
          keyboardAccessible={true}
          titleAccess={'Navigate to roadside assistance'}
          onClick={() => {
            this.handleRoadsideCTAClick(roadsideUrl);
          }}
        />
      </Blade>
    );
  };

  public renderLifeInsuranceBlade = (lifeProduct: CustomerDetailsProduct) => {
    return (
      <Blade
        key={`${lifeProduct.type} `}
        id="lifeinsurance-link"
        onClick={() => this.handleExternalLink(this.props.config?.InsuranceURLs?.lifeInsuranceURL)}
      >
        <BladeContent
          id={`${lifeProduct.productId}-content`}
          mainTitle={`${lifeProduct.productName} `}
          subTitle1="Manage your life insurance policy"
          icon={BladeIconType.RIGHT_ARROW_BOLD}
          leftIcon={BladeIconType[lifeProduct.type]}
          keyboardAccessible={true}
          titleAccess={`Navigate to ${lifeProduct.productName}`}
          onClick={() => this.handleExternalLink(this.props.config?.InsuranceURLs?.lifeInsuranceURL)}
        />
      </Blade>
    );
  };

  public renderTravelBlade = (roadsideUrl: string) => {
    if (this.state.travelReservations.length)
      return (
        <Blade
          key="travel"
          id="travel-link"
          onClick={() => {
            this.handleExternalLink(roadsideUrl);
          }}
        >
          <BladeContent
            id="travel-content"
            mainTitle="Travel"
            subTitle1="Manage my booking"
            subTitle2=""
            icon={BladeIconType.RIGHT_ARROW_BOLD}
            leftIcon={BladeIconType.TRAVEL}
            keyboardAccessible={true}
            titleAccess={`Navigate to travel`}
            onClick={() => {
              this.handleExternalLink(roadsideUrl);
            }}
          />
        </Blade>
      );
    return <React.Fragment key="travel"></React.Fragment>;
  };

  public onClickSafelite = (safeliteUrl: string, membershipExpDate: string = '') => {
    const analytics = this.props.analytics;
    if (analytics) tagSafeliteBlade(analytics.model);

    WebUtils.externalLinkHandler(
      `${safeliteUrl}?membernumber=${this.props.userState?.membershipNumber}&expdate=${WebUtils.convertDate(
        membershipExpDate
      )}`,
      false,
      true,
      this.props.userState?.customerId,
      this.props.userState?.membershipNumber,
      false
    ).catch(() => {});
  };

  public renderSafeliteBlade = (safeliteUrl: string, membershipExpDate: string = '') => {
    return (
      <Blade
        key="safelite"
        id="safelite-link"
        onClick={() => {
          this.onClickSafelite(safeliteUrl, membershipExpDate);
        }}
      >
        <BladeContent
          id="safelite-content"
          mainTitle="Premier Windshield Repair"
          subTitle1="Fast and reliable repair by a mobile technician"
          subTitle2="NEW"
          icon={BladeIconType.RIGHT_ARROW_BOLD}
          leftIcon={BladeIconType.SAFELITE}
          keyboardAccessible={true}
          titleAccess={`Navigate to safelite`}
          onClick={() => {
            this.onClickSafelite(safeliteUrl, membershipExpDate);
          }}
        />
      </Blade>
    );
  };

  public render() {
    let membershipExpDate;
    let membershipNumber;
    const cd = this.props.customerDetails;
    let allBlades: any[] = [];
    let lifeInsuranceProduct: any;
    if (cd) {
      lifeInsuranceProduct = cd.products.find((product) => product.type === ProductType.LIFE);
      const isSafeLiteEnabled = Boolean(
        this.props.config?.SafeliteEnabled?.includes('ALL') ||
          this.props.config?.SafeliteEnabled?.includes(this.props.userState?.clubCode)
      );
      const membershipType = cd.membershipInfo?.membershipType.toUpperCase();
      const isPremier = membershipType === 'PREMIER' || membershipType === 'PREMIER-RV';
      const membershipProduct = cd.products.find((product) => product.type === ProductType.MEMBERSHIP);
      membershipNumber = membershipProduct?.productId;
      membershipExpDate = membershipProduct?.productExpiration;
      allBlades = [
        cd.products.map((product, index) => {
          return this.renderProductBlade(product, index, cd);
        }),
      ];
      if (lifeInsuranceProduct) {
        allBlades.push(this.renderLifeInsuranceBlade(lifeInsuranceProduct));
      }
      if (this.props.config) {
        allBlades.push(this.renderRoadSideBlade(this.props.config.roadsideAssistanceURL));
        if (isSafeLiteEnabled && isPremier) {
          allBlades.push(this.renderSafeliteBlade(this.props.config.SafeliteURL, membershipExpDate));
        }
        allBlades.push(this.renderTravelBlade(this.props.config.TravelReservationsURL));
      }
    }

    const customerData: Customer = GetSourceParam(SourceParam.customerData);
    const customerApiError = customerData.apiStatus?.customer === 'rejected';

    const productsData: Customer = GetSourceParam(SourceParam.productsData);
    const billingApiError = productsData.apiStatus?.billing === 'rejected';
    const policyApiError = productsData.apiStatus?.policy === 'rejected';

    return (
      <div>
        {(billingApiError || policyApiError || customerApiError || this.props.showNotificationError) && (
          <ErrorCard billingError={billingApiError} policyError={policyApiError} customerError={customerApiError} />
        )}

        <CardsContainer
          id="Home-CardsContainer"
          config={this.props.config}
          customerDetailsStatus={this.props.customerDetailsStatus}
          cardTypes={[
            CardCodeEnum.ACG,
            CardCodeEnum.BILLING,
            CardCodeEnum.ENROLL_AUTO_PAY_INSURANCE,
            CardCodeEnum.ENROLL_PAPERLESS,
            CardCodeEnum.IQ,
            CardCodeEnum.CREDIT_CARD,
            CardCodeEnum.PMID,
          ]}
          membershipExpDate={membershipExpDate}
          membershipNumber={membershipNumber}
          showHeader
        />
        <br />

        {allBlades}

        {this.state.product && (
          <Modal
            id={`${this.state.product.type} -modal`}
            title={`${
              this.state.product.productId.startsWith('CAC')
                ? 'Classic/Collector car insurance policy'
                : this.state.product.productName
            } options`}
            openModal={this.state.showOptionsOverlayModal}
            subTitle="Select one"
            onClose={() => this.handleModal(this.state.product, false)}
            disableBackdropClick={true}
            content={
              this.state.isFetching ? (
                <Spinner id="spinner" variant="indeterminate" message="" />
              ) : (
                <SelectListOverlay
                  id="membershipOptionList"
                  items={this.getOverlayItems()}
                  onSelect={() => {}}
                  suppressOpenUrlOnSelect={this.handleOptionSelect}
                />
              )
            }
          />
        )}
      </div>
    );
  }
}

// This is needed for the test to run successful
export { HomeTabContent };

export default connect(mapStateToProps, mapDispatchToProps)(HomeTabContent);
