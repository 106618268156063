import { ProductType } from '../contracts/ProductType';

/** Club codes */
export enum ClubCodes {
  AL = '001',
  CA = '004',
  HI = '018',
  ACMO = '065',
  NM = '601',
  NNE = '036',
  TW = '258',
  TX = '252',
  EC = '215',
}

export enum ApiStatus {
  UNINITIATED = 'UNINITIATED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

/** IconFlags */
export enum IconFlags {
  IS_PASTDUE = 'IS_PASTDUE',
  IS_IN_TRANSIT = 'IS_IN_TRANSIT',
  IS_RECURRING = 'IS_RECURRING',
  SELL_BLACK = 'SELL_BLACK',
}

/** MessageType */
export enum MessageType {
  PAYMENT = 'PAYMENT',
  ACTION = 'ACTION',
}

/** PayPlan */
export enum PayPlan {
  MONTHLY_PAY = 'MONTHLY_PAY',
  ANNUAL_MANUAL = 'ANNUAL_MANUAL',
  ANNUAL_CREDIT = 'ANNUAL_CREDIT',
  ANNUAL_ECHECK = 'ANNUAL_ECHECK',
  ANNUAL_DONOR = 'ANNUAL_DONOR',
  ANNUAL_GIFT_DONOR = 'ANNUAL_GIFT_DONOR',
  BUSINESS = 'BUSINESS',
}

export enum BillPlan {
  AM = 'Annual Manual',
  AC = 'Annual Credit',
  AH = 'Annual eCheck',
  MP = 'Monthly Pay',
  AD = 'Annual Donor',
  AX = 'Annual Holiday Gift Donor',
  BA = 'Business',
}

// todo: we might need to delete the two enums above and use this instead
// todo: rename this to BillPlan
export enum MembershipPaymentPlan {
  AM = 'AM',
  AC = 'AC',
  AH = 'AH',
  MP = 'MP',
  AD = 'AD',
  AX = 'AX',
  BA = 'BA',
}

export enum MembershipStatus {
  Valid = 'valid',
  Cancelled = 'cancelled',
  Spinoff = 'spinoff',
}

export enum MembershipType {
  CLASSIC = 'Classic',
  PLUS = 'Plus',
  PREMIER = 'Premier',
  PENDING = 'Pending',
  UNKNOWN = 'Unknown',
}

class MembershipTypeClass {
  private CLASSIC = { codes: ['CLASSIC', 'CLASSIC RV', 'CLASSIC-RV'], abbr: 'cls', description: 'Classic' };

  private PLUS = { codes: ['PLUS', 'PLUS RV', 'PLUS-RV'], abbr: 'pls', description: 'Plus' };

  private PREMIER = { codes: ['PREMIER', 'PREMIER RV', 'PREMIER-RV'], abbr: 'pre', description: 'Premier' };

  private PENDING = { codes: ['RV'], abbr: 'pen', description: 'Pending' };

  private UNKNOWN = { codes: ['UNKNOWN'], abbr: 'unknown', description: 'UNKNOWN' };

  private ALL_MEMBERSHIP_TYPES = [this.CLASSIC, this.PLUS, this.PREMIER, this.PENDING];

  public findByCode(code: string = '') {
    const result =
      this.ALL_MEMBERSHIP_TYPES.find((type) =>
        type.codes.some((typeCode) => typeCode.toLowerCase() === code.toLowerCase())
      ) || this.UNKNOWN;
    return result;
  }
}

export const MembershipTypeTemp = new MembershipTypeClass();

export enum RoleCodes {
  PRIMARY = '00',
  DEPENDENT_ASSOCIATE_01 = '01',
  DEPENDENT_ASSOCIATE_02 = '02',
  DEPENDENT_ASSOCIATE_03 = '03',
  DEPENDENT_ASSOCIATE_04 = '04',
  DEPENDENT_ASSOCIATE_05 = '05',
  ADULT_ASSOCIATE = '06',
}

export enum RoleCodesTWNNE {
  PRIMARY = '00',
  ASSOCIATE_01 = '01',
  ASSOCIATE_02 = '02',
  ASSOCIATE_03 = '03',
  ASSOCIATE_04 = '04',
  ASSOCIATE_05 = '05',
  ASSOCIATE_06 = '06',
}

export enum NotificationType {
  BILLING_DUE = 'BILLING_DUE',
  BILLING_PAID = 'BILLING_PAID',
  BILLING_REINSTATE = 'BILLING_REINSTATE',
  PAYMENT_SCHEDULED = 'PAYMENT_SCHEDULED',
  PAYMENT_IN_TRANSIT = 'PAYMENT_IN_TRANSIT',
  BILLING_INFO = 'BILLING_INFO',
  INSURANCE_QUESTIONNAIRE = 'INSURANCE_QUESTIONNAIRE',
}

export enum State {
  AL = 'AL',
  AR = 'AR',
  CA = 'CA',
  HI = 'HI',
  IN = 'IN',
  IL = 'IL',
  OH = 'OH',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MD = 'MD',
  ME = 'ME',
  MO = 'MO',
  MS = 'MS',
  PA = 'PA',
  NH = 'NH',
  NM = 'NM',
  NY = 'NY',
  TX = 'TX',
  VA = 'VA',
  VT = 'VT',
  WV = 'WV',
}

export class Club {
  AL = {
    abbreviation: 'AL',
    code: '001',
    name: 'Alabama',
    domain: 'alabama',
    state: {
      AL: State.AL,
    },
  };

  CA = {
    abbreviation: 'CA',
    code: '004',
    name: 'California',
    domain: 'calif',
    state: {
      CA: State.CA,
    },
  };

  HI = {
    abbreviation: 'HI',
    code: '018',
    name: 'Hawaii',
    domain: 'hawaii',
    state: {
      HI: State.HI,
    },
  };

  NNE = {
    abbreviation: 'NNE',
    code: '036',
    name: 'Northern New England',
    domain: 'northernnewengland',
    state: {
      ME: State.ME,
      NH: State.NH,
      VT: State.VT,
    },
  };

  ACMO = {
    abbreviation: 'ACMO',
    code: '065',
    name: 'Missouri',
    domain: 'autoclubmo',
    state: {
      AR: State.AR,
      IL: State.IL,
      IN: State.IN,
      KS: State.KS,
      LA: State.LA,
      MO: State.MO,
      MS: State.MS,
    },
  };

  EC = {
    abbreviation: 'EC',
    code: '215',
    name: 'East Central',
    domain: 'eastcentral',
    state: {
      KY: State.KY,
      NY: State.NY,
      OH: State.OH,
      PA: State.PA,
      WV: State.WV,
    },
  };

  TX = {
    abbreviation: 'TX',
    code: '252',
    name: 'Texas',
    domain: 'texas',
    state: {
      TX: State.TX,
    },
  };

  TW = {
    abbreviation: 'TW',
    code: '258',
    name: 'Tidewater',
    domain: 'tidewater',
    state: {
      MD: State.MD,
      VA: State.VA,
    },
  };

  NM = {
    abbreviation: 'NM',
    code: '601',
    name: 'New Mexico',
    domain: 'newmexico',
    state: {
      NM: State.NM,
    },
  };

  ALL_CLUBS = [this.AL, this.CA, this.HI, this.NNE, this.ACMO, this.EC, this.TX, this.TW, this.NM];

  public findByAbbreviation(abbreviation: string) {
    return this.ALL_CLUBS.find((club) => club.abbreviation === abbreviation)!;
  }

  public findByClubCode(code: string) {
    return this.ALL_CLUBS.find((club) => club.code === code)!;
  }

  public findByDomain(domain: string) {
    return this.ALL_CLUBS.find((club) => club.domain === domain)!;
  }
}

export const clubcode = new Club();
export const CLUB_CODE = new Club();

//used to fetch role type(PR,AS,AD and DE) using rolecode(00,01....06) and clubcode(001,252,004....)
export class Role {
  PR = {
    roleType: 'PR',
    roleCode: ['00'],
    clubs: [
      ClubCodes.TW,
      ClubCodes.AL,
      ClubCodes.CA,
      ClubCodes.EC,
      ClubCodes.HI,
      ClubCodes.ACMO,
      ClubCodes.NM,
      ClubCodes.NNE,
      ClubCodes.TX,
    ],
  };

  AS = {
    roleType: 'AS',
    roleCode: ['01', '02', '03', '04', '05', '06'],
    clubs: [ClubCodes.TW, ClubCodes.NNE],
  };

  DE = {
    roleType: 'DE',
    roleCode: ['01', '02', '03', '04', '05'],
    clubs: [ClubCodes.AL, ClubCodes.CA, ClubCodes.EC, ClubCodes.HI, ClubCodes.ACMO, ClubCodes.NM, ClubCodes.TX],
  };

  // tw and nne
  AD = {
    roleType: 'AD',
    roleCode: ['06'],
    clubs: [ClubCodes.AL, ClubCodes.CA, ClubCodes.EC, ClubCodes.HI, ClubCodes.ACMO, ClubCodes.NM, ClubCodes.TX],
  };

  ALL_ROLE_CODES = [this.PR, this.AS, this.DE, this.AD];

  public getRoleTypefromRoleCodeClubCode(roleCode: string, clubCode: ClubCodes) {
    const club = this.ALL_ROLE_CODES.find(
      (roleClub) => roleClub.roleCode.some((r) => r === roleCode) && roleClub.clubs.some((c) => c === clubCode)
    );
    return club?.roleType || '';
  }

  public getRolefromRoleTypeClubCode(roleType: string, clubCode: ClubCodes) {
    return this.ALL_ROLE_CODES.find(
      (roleClub) => roleClub.roleType === roleType && roleClub.clubs.some((c) => c === clubCode)
    );
  }
}
export const role = new Role();

export enum AppPermissions {
  CAN_MANAGE_MEMBERSHIP,
  CAN_MANAGE_INSURANCE,
}

export enum AuthenticationResponseCodes {
  TOKEN_EXPIRED = 0,
  TOKEN_VALID = 1,
  TOKEN_INVALID = 2,
}

export const ProductTypeDetails = {
  MEMBERSHIP: { name: 'MEMBERSHIP' },
  INSURANCE_QUESTIONNAIRE: { name: 'INSURANCE QUESTIONNAIRE' },
  AUTO: { name: 'AUTO' },
  HOME: { name: 'HOME' },
  CONDO: { name: 'CONDO' },
  TENENTS: { name: 'TENANTS' },
  RENTAL_DWELLING: { name: 'RENTAL DWELLING' },
  CALIFORNIA_EARTHQUAKE: { name: 'CALIFORNIA EARTHQUAKE' },
  WATERCRAFT: { name: 'WATERCRAFT' },
  PERSONAL_UMBRELLA: { name: 'PERSONAL UMBRELLA' },
  LIFE: { name: 'LIFE' },
};

export const InsuranceOptionsDisplays = {
  PAY_MY_BILL: 'Pay my bill',
  GET_PROOF_OF_INSURANCE: 'Get proof of insurance cards',
  COMPLETE_ANNUAL_INSURANCE_QUESTIONNAIRE: 'Complete annual insurance questionnaire',
  GET_CLAIM_STATUS: 'Get claim status',
  CHANGE_POLICY: 'View & manage policy',
  CHANGE_MY_CONTACT_INFORMATION: 'Change my contact information',
  MANAGE_PAYMENT_PLAN: 'Enroll in monthly payment plan',
  ENROLL_AUTO_PAY: 'Enroll in Auto Pay',
  MANAGE_AUTO_PAY: 'Manage Auto Pay',
  VIEW_PAYMENT_AND_BILLING_HISTORY: 'View billing & payment history',
  VIEW_POLICY_DETAILS: 'View policy',
  VIEW_ALL_INSURANCE_DISCOUNTS: 'View all AAA insurance discounts',
  DONT_SEE: "Don't see what you're looking for?",
};

export const InsuranceOptionEnum = {
  PAY_MY_BILL: 'PAY_MY_BILL',
  GET_PROOF_OF_INSURANCE: 'GET_PROOF_OF_INSURANCE',
  COMPLETE_ANNUAL_INSURANCE_QUESTIONNAIRE: 'COMPLETE_ANNUAL_INSURANCE_QUESTIONNAIRE',
  GET_CLAIM_STATUS: 'GET_CLAIM_STATUS',
  CHANGE_POLICY: 'CHANGE_POLICY',
  CHANGE_MY_CONTACT_INFORMATION: 'CHANGE_MY_CONTACT_INFORMATION',
  MANAGE_PAYMENT_PLAN: 'MANAGE_PAYMENT_PLAN',
  ENROLL_AUTO_PAY: 'ENROLL_AUTO_PAY',
  MANAGE_AUTO_PAY: 'MANAGE_AUTO_PAY',
  VIEW_PAYMENT_AND_BILLING_HISTORY: 'VIEW_PAYMENT_AND_BILLING_HISTORY',
  VIEW_POLICY_DETAILS: 'VIEW_POLICY_DETAILS',
  VIEW_ALL_INSURANCE_DISCOUNTS: 'VIEW_ALL_INSURANCE_DISCOUNTS',
  DONT_SEE: 'DONT_SEE',
};

export const MembershipOptionEnum = {
  PAY_MY_BILL: 'PAY_MY_BILL',
  MANAGE_MEMBERSHIP: 'MANAGE_MEMBERSHIP',
  GET_MEMBERSHIP_CARD: 'GET_MEMBERSHIP_CARD',
  MANAGE_AUTOMATIC_PAYMENTS: 'MANAGE_AUTOMATIC_PAYMENTS',
  ENROLL_AUTOMATIC_PAYMENTS: 'ENROLL_AUTOMATIC_PAYMENTS',
  VIEW_MEMBER_GUIDE: 'VIEW_MEMBER_GUIDE',
  DONT_SEE: 'DONT_SEE',
};

class PaymentMethodClass {
  private CREDIT = { code: ['C', 'CC', 'CCRD'], description: 'Credit/Debit Card' };

  private DEBIT = { code: ['D'], description: 'Credit/Debit Card' };

  private ACH = { code: ['A', 'E', 'ACH', 'BCHK'], description: 'eCheck' };

  // private BCHK = { code: 'BCHK', description: 'eCheck' };
  // private CCRD = { code: 'CCRD', description: 'Credit Card' };
  private UNKNOWN = { code: 'UNKNOWN', description: 'UNKNOWN' };

  private ALL_PAYMENT_METHODS = [this.CREDIT, this.DEBIT, this.ACH];

  public findByCode(c: string) {
    return this.ALL_PAYMENT_METHODS.find((pm) => pm.code.includes(c.toUpperCase())) || this.UNKNOWN;
    // const result = this.ALL_PAYMENT_METHODS.find(pm => pm.code === code);
    // return result ? result : this.UNKNOWN;
  }
}

export const PaymentMethod = new PaymentMethodClass();

// should be deprecated and use PaymentMethod
export enum CardType {
  D = 'Debit card',
  C = 'Credit card',
}

export class AwarenessCardType {
  INSURANCE_AUTOPAY_ENROLL = {
    key: 'INSURANCE_AUTOPAY_ENROLL',
  };

  ID_THEFT = {
    key: 'ID_THEFT',
  };

  UNKNOWN = {};

  private ALL_CARD_TYPES = [this.INSURANCE_AUTOPAY_ENROLL, this.ID_THEFT];

  public findByName(cardName: string) { }

  public findByDescription(key: string) {
    return this.ALL_CARD_TYPES.find((p) => p.key === key) || this.UNKNOWN;
  }
}
export const AWARENESS_CARD = new AwarenessCardType();

export enum MvcRedirectKey {
  POLICY_DETAILS = 'POLICY_DETAILS',
  POLICY_DETAILS_AWS = 'POLICY_DETAILS_AWS',
  BILLING_SUMMARY = 'BILLING_SUMMARY',
  ONLINE_BILL_PAY = 'ONLINE_BILL_PAY',
  BILLING_SUMMARY_AWS = 'BILLING_SUMMARY_AWS'
}

export enum MvcRedirectMode {
  AUTO = 'AUTO',
  HOME = 'HOME',
  WATERCRAFT = 'WATERCRAFT',
  PERSONAL_UMBRELLA = 'PERSONAL_UMBRELLA',
  //below used only for pay bill mvc end point
  PAYBUTTON = 'PAYBUTTON',
  ENROLLLINK = 'ENROLLLINK',
  PAYANDENROLLLINK = 'PAYANDENROLLLINK',
}

export enum SecurityModalType {
  MEMBERSHIP = 'MEMBERSHIP',
  INSURANCE = 'INSURANCE',
  INSURANCE_QUESTIONNAIRE = 'INSURANCE_QUESTIONNAIRE',
  ONBOARD_REGISTRATION = 'ONBOARD_REGISTRATION',
}

export enum FeatureFlags {
  STANDIN_CUSTOMERDETAILRULES = 'StandInCustomerDetailRules',
  AUTHENTICATION_TOKEN_RENEWAL = 'AuthenticationTokenRenewal',
}

export enum SourceSystem {
  MRD = 'MRD',
  LIF = 'LIF',
  HUON = 'HUON',
  'Policy Star' = 'Policy Star',
  GW = 'GUIDEWIRE'
}

// class SourceSystemClass {
//   MRD = {
//     description: '',
//     code: 'MRD'
//   }
//   HUON = {
//     description: '',
//     code: 'HUON'
//   }
//   PSTAR = {
//     description: '',
//     code: 'PSTAR'
//   }
// }
// export const SOURCE_SYSTEM = new SourceSystemClass()
// class ProductTypePrefixClass {
//   // Auto Policy Prefixes
//   PPA = {
//     description: "California Auto",
//     code: 'PPA',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   PCA = {
//     description: "California Auto",
//     code: 'PCA',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   PAP = {
//     description: "California Auto",
//     code: 'PAP',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   TPA = {
//     description: 'Texas Auto',
//     code: 'TPA',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   // Home Policy Prefixes
//   HBC = {
//     description: "Home",
//     code: 'HBC',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   HMC = {
//     description: 'Home',
//     code: 'HMC',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   HOA = {
//     description: 'Home',
//     code: 'HOA',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   HOB = {
//     description: "Home",
//     code: 'HOB',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   HOD = {
//     description: 'Home',
//     code: 'HOD',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   HOM = {
//     description: 'Home',
//     code: 'HOM',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   HOP = {
//     description: "Home",
//     code: 'HOP',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   HO5 = {
//     description: 'Home',
//     code: 'HO5',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
//   HO8 = {
//     description: 'Home',
//     code: 'HO8',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }

//   // Earthquake
//   CEA = {
//     description: 'California Earthquake',
//     code: 'CEA',
//     sourceSystem: SOURCE_SYSTEM.HUON
//   }
// }
// export const PRODUCT_TYPE_PREFIX = new ProductTypePrefixClass()

// class LineOfBusinessClass {
//   INSURANCE = {
//     code: '20'
//   }
//   LIFE = {
//     code: '15'
//   }
// }
// export const LINE_OF_BUSINESS = new LineOfBusinessClass()

/** The ProductType value is just temporary for backwards compatibility */
/** Currently excluding Auto 'Classic' ? ? ? */
export class ProductTypeEnum {
  MEMBERSHIP = {
    type: ProductType.MEMBERSHIP,
    taggingNoAuthValue: '',
    description: ['Membership'],
    // lineOfBusiness: '',
    // prefixes: []
  };

  AUTO = {
    type: ProductType.AUTO,
    taggingNoAuthValue: 'manage my account:don’t see what you are looking for-auto',
    description: ['Auto Insurance', 'Auto'],
    // lineOfBusiness: LINE_OF_BUSINESS.INSURANCE,
    // prefixes: [
    //   {
    //     club: CLUB_CODE.CA,
    //     codes: [
    //       PRODUCT_TYPE_PREFIX.PCA,
    //       PRODUCT_TYPE_PREFIX.PPA,
    //       PRODUCT_TYPE_PREFIX.PAP
    //     ]
    //   }
    // ]
  };

  HOME = {
    type: ProductType.HOME,
    taggingNoAuthValue: 'manage my account:don’t see what you are looking for-home',
    description: ['Home Insurance', 'Home', 'HO5', 'HO8', 'HBC', 'HMC', 'HOM', 'HOA', 'HOD', 'HOB'],
    // lineOfBusiness: LINE_OF_BUSINESS.INSURANCE,
    // prefixes: [
    //   {
    //     club: CLUB_CODE.CA,
    //     codes: [
    //        PRODUCT_TYPE_PREFIX.HBC
    //       ,PRODUCT_TYPE_PREFIX.HMC
    //       ,PRODUCT_TYPE_PREFIX.HOA
    //       ,PRODUCT_TYPE_PREFIX.HOB
    //       ,PRODUCT_TYPE_PREFIX.HOD
    //       ,PRODUCT_TYPE_PREFIX.HOM
    //       ,PRODUCT_TYPE_PREFIX.HOP
    //       ,PRODUCT_TYPE_PREFIX.HO5
    //       ,PRODUCT_TYPE_PREFIX.HO8
    //     ]
    //   }
    // ]
  };

  EARTHQUAKE = {
    type: ProductType.CALIFORNIA_EARTHQUAKE,
    taggingNoAuthValue: 'manage my account:don’t see what you are looking for-home',
    description: ['Earthquake Insurance', 'Earthquake', ProductType.CALIFORNIA_EARTHQUAKE],
    // lineOfBusiness : LINE_OF_BUSINESS.INSURANCE,
    // prefixes: [
    //   {
    //     club: CLUB_CODE.CA,
    //     codes: [PRODUCT_TYPE_PREFIX.CEA]
    //   }
    // ]
  };

  CONDO = {
    type: ProductType.CONDO,
    taggingNoAuthValue: 'manage my account:don’t see what you are looking for-home',
    description: ['Con', 'Condo'],
    // lineOfBusiness: '',
    // prefixes: []
  };

  TENANT = {
    type: ProductType.TENANT,
    taggingNoAuthValue: 'manage my account:don’t see what you are looking for-home',
    description: ['Ten', 'Tenant'],
    // lineOfBusiness: '',
    // prefixes: []
  };

  RENTAL_DWELLING = {
    type: ProductType.RENTAL_DWELLING,
    taggingNoAuthValue: 'manage my account:don’t see what you are looking for-home',
    description: ['RDP', 'RDC', ProductType.RENTAL_DWELLING],
    // lineOfBusiness: '',
    // prefixes: []
  };

  WATERCRAFT = {
    type: ProductType.WATERCRAFT,
    taggingNoAuthValue: 'manage my account:don’t see what you are looking for-watercraft',
    description: ['Watercraft'],
    // lineOfBusiness: '',
    // prefixes: []
  };

  UMBRELLA = {
    type: ProductType.PUP,
    taggingNoAuthValue: 'manage my account:don’t see what you are looking for-umbrella',
    description: ['PUP', ProductType.PUP],
    // lineOfBusiness: '',
    // prefixes: []
  };

  LIFE = {
    type: ProductType.LIFE,
    taggingNoAuthValue: '',
    description: ['Life', 'Life Insurance'],
    // lineOfBusiness: '',
    // prefixes: []
  };

  UNKNOWN = {
    type: '',
    taggingNoAuthValue: '',
    description: [],
    // lineOfBusiness: '',
    // prefixes: []
  };

  private ALL_PRODUCT_TYPES = [
    this.MEMBERSHIP,
    this.AUTO,
    this.HOME,
    this.EARTHQUAKE,
    this.CONDO,
    this.TENANT,
    this.RENTAL_DWELLING,
    this.WATERCRAFT,
    this.UMBRELLA,
    this.LIFE,
  ];

  public findByDescription(desc: string) {
    return (
      this.ALL_PRODUCT_TYPES.find((p) => p.description.some((d) => d.toUpperCase() === desc.toUpperCase())) ||
      this.UNKNOWN
    );
  }
}
export const PRODUCT_TYPE = new ProductTypeEnum();

// export class ProductTypeAggregate {

//   // exclude life/membership
//   private AGGREGATE_PRODUCTS = [PRODUCT_TYPE.EARTHQUAKE, PRODUCT_TYPE.AUTO]

//   public findByProductID(productID: string) {
//     const prefix = productID.substring(0, 3);
//     const result = this.AGGREGATE_PRODUCTS.find(
//       p => p.prefixes.find(
//         pr => pr.codes.find(
//           prs => prefix === prs.code
//         )
//       )
//     );
//     return result || PRODUCT_TYPE.UNKNOWN;
//   };

// }

// export const PRODUCT_PREFIX = new ProductTypeAggregate();
class ProductStatusClass {
  private CANCELLED = { code: 'CANC', taggingValue: 'cnp' };

  private TERMINATED = { code: 'NONR', taggingValue: 'trnp' };

  private VALID = { code: 'VALD', taggingValue: '' };

  private NORMAL = { code: 'NORM', taggingValue: '' };

  private UNKNOWN = { code: 'UNKNOWN', taggingValue: 'UNKNOWN' };

  private ALL_PRODUCT_STATUS = [this.CANCELLED, this.TERMINATED, this.VALID];

  public findByCode(code: string) {
    const result = this.ALL_PRODUCT_STATUS.find((pm) => pm.code === code);
    return result ? result : this.UNKNOWN;
  }
}

export const ProductStatus = new ProductStatusClass();

export enum TaggingSource {
  NotificationCard = 'NotificationCard',
  Overlay = 'Overlay',
  Blade = 'Blade',
}

export enum UISource {
  NotificationCard = 'NotificationCard',
  Overlay = 'Overlay',
  Blade = 'Blade',
}

export enum CardCodeEnum {
  ENROLL_AUTOPAY = 'ENROLL_AUTOPAY',
  ENROLL_AUTO_PAY_MEMBERSHIP = 'ENROLL_AUTO_PAY_MEMBERSHIP',
  ENROLL_AUTO_PAY_INSURANCE = 'ENROLL_AUTO_PAY_INSURANCE',
  ENROLL_PAPERLESS = 'ENROLL_PAPERLESS',
  ENROLL_PAPERLESS_MAGAZINE = 'ENROLL_PAPERLESS_MAGAZINE',
  ENROLL_PAPERLESS_MEMBERCARD = 'ENROLL_PAPERLESS_MEMBERCARD',
  GET_AUTO_QUOTE = 'GET_AUTO_QUOTE',
  BILLING = 'BILLING',
  ACG = 'ACG',
  IQ = 'IQ',
  PAYMENT_IN_TRANSIT = 'PAYMENT_IN_TRANSIT',
  CREDIT_CARD = "CREDIT_CARD",
  PMID = "PMID",
}

//maps API awarenesscard codes to myaccount card
export class AwarenessCardCode {
  ENROLL_AUTOPAY = {
    apiCardCode: 'ENROLL_AUTOPAY',
    type: CardCodeEnum.ENROLL_AUTOPAY,
  };
  
  ENROLL_AUTO_PAY_MEMBERSHIP = {
    apiCardCode: 'ENROLL_AUTO_PAY_MEMBERSHIP',
    type: CardCodeEnum.ENROLL_AUTO_PAY_MEMBERSHIP,
  };

  ENROLL_AUTO_PAY_INSURANCE = {
    apiCardCode: 'ENROLL_AUTO_PAY_INSURANCE',
    type: CardCodeEnum.ENROLL_AUTO_PAY_INSURANCE,
  };

  ENROLL_PAPERLESS = { apiCardCode: 'ENROLL_PAPERLESS', type: CardCodeEnum.ENROLL_PAPERLESS };

  ENROLL_PAPERLESS_MAGAZINE = {
    apiCardCode: 'ENROLL_PAPERLESS_MAGAZINE',
    type: CardCodeEnum.ENROLL_PAPERLESS_MAGAZINE,
  };

  ENROLL_PAPERLESS_MEMBERCARD = {
    apiCardCode: 'ENROLL_PAPERLESS_MEMBERCARD',
    type: CardCodeEnum.ENROLL_PAPERLESS_MEMBERCARD,
  };

  GET_AUTO_QUOTE = { apiCardCode: 'GET_AUTO_QUOTE', type: CardCodeEnum.GET_AUTO_QUOTE };

  UNKNOWN = { apiCardCode: 'UNKNOWN', type: undefined };

  private ALL_CARD_CODES = [
    this.ENROLL_AUTO_PAY_MEMBERSHIP,
    this.ENROLL_AUTO_PAY_INSURANCE,
    this.ENROLL_PAPERLESS,
    this.ENROLL_PAPERLESS_MAGAZINE,
    this.ENROLL_PAPERLESS_MEMBERCARD,
    this.GET_AUTO_QUOTE,
  ];

  public findTypeByCardCode(apiCode: string) {
    const result = this.ALL_CARD_CODES.find((ac) => ac.apiCardCode === apiCode);
    return result ? result : this.UNKNOWN;
  }
}

export const awarenessCardCode = new AwarenessCardCode();

export enum WebLogLevel {
  debug = 'debug',
  warn = 'warn',
  error = 'error',
  info = 'info',
}

export enum ApiRefIdSuffix {
  Config = '000',
  SecurityToken = '001',
  CustomerDetails = '002',
  MembershipOptions = '004',
  InsuranceOptions = '005',
  Notifications = '006',
  AwarenessCards = '008',
  UpdateUserPreference = '009',
  PolicyView = '010',
}

export enum AutoPolicyPrefixes {
  CAClassicCar = 'CAC'
}