import { RemotePost } from 'remotes';
import {
  aceMetaFixture,
  RedirectContractApp,
} from 'shared';
import { RedirectRequestApp, RedirectResponseApp } from 'shared/models/redirectModel';

export const RedirectRemoteApp = (endpoint: string): RedirectContractApp =>
  RemotePost<RedirectRequestApp, RedirectResponseApp>(endpoint);

  export const RedirectStubApp =
  (_endpoint: string): RedirectContractApp =>
  async ({ url }) => ({
    aceMeta: aceMetaFixture,
    data: {
      url: decodeURIComponent(url).replace("<domain>", "calif"),
    }
  });