/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetSourceParam, SetSourceParam, SourceParam, configService, httpInstance } from 'remotes';
import { ACEPrimaryTheme, TabGroup, LayoutContainer, Spinner } from '@clublabs/ace-component-library';
import HomeTabContent from '../Home/HomeTabContent';
import { AceHeader, AceFooter } from '@clublabs/shared-nav-webcomponents-react';
import {
  tagApplicationPageLoad,
  tagMyAccountView,
  tagPayBillView,
  tagProfileView,
  tagProfilePageLoad,
  loadUtagJs,
} from '../Common/TaggingHelper';
import Welcome from '../Welcome/Welcome';
import {
  CustomerDetailsResponseApp,
  taggingMapper,
  createAnalytics,
  ConfigResponseApp,
  ClubCodes,
  CustomerDetails,
  TaggingSource,
  FormPostKeyValue,
  WebLogLevel,
  ApiStatus,
  authenticateFixture,
  CustomerDetailsProduct,
  ProductType,
  networkBrowserErrors,
  TaggingModel,
} from 'shared';
import { setUserState } from '../../redux/store/Actions/user.actions';
import { UserState } from '../../redux/store/Reducers/user.reducer';
import { setErrorState } from '../../redux/store/Actions/error.actions';
import { ErrorState } from '../../redux/store/Reducers/error.reducer';
import { setAnalyticsState } from '../../redux/store/Actions/analytics.actions';
import { AnalyticsState } from 'redux/store/Reducers/analytics.reducer';
import { setActiveTab } from '../../redux/store/Actions/tabcontainer.actions';
import { RootState } from '../../redux/store/rootReducer';
import { ServiceError, Timer, utility, WebUtils } from 'utils';
import ProfileTabContent, { ProfileTabProps } from '../Profile/ProfileTabContent';
import ErrorComp from '../Error/ErrorComp';
import SecurityModal from '../Common/SecurityModal';
import CancelledPolicyModal from '../Common/CancelledPolicyModal';
import { setModalState } from '../../redux/store/Actions/modal.actions';
import { ModalState } from '../../redux/store/Reducers/modal.reducer';
import jwtDecode from 'jwt-decode';
import { useEffect, useState, useRef } from 'react';
import { useGlobalState } from 'shared/state';
import { appSources } from 'remotes';
import { ProfileTabEnum } from 'shared/common/profileTab';
import Cookies from 'universal-cookie';
import AceIdentity from '@clublabs/ace-identity';
import { ErrorTypes, MembershipStatus } from '../../shared';
import Error from '../Error/Error';
import LogCannon from '@clublabs/log-cannon';
import { TeamValue, WorkloadValue } from '@clublabs/aws-tags';
import { v4 as uuid } from 'uuid';
import { Customer } from 'shared/mappers/customerMapper';

const initialCustomerDetailData = {
  data: {
    welcomeMessage: '',
    customerId: '',
    preferredEmailAddress: {
      emailAddress: '',
      lastUpdateDate: '',
    },
    numberOfProducts: 0,
    products: [] as CustomerDetailsProduct[],
    emptyAuthorizations: false,
  },
  status: ApiStatus.UNINITIATED,
};

const App: React.FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const [clubCode, setClubCode] = useState(WebUtils.getClubCode());
  const [notificationError, setNotificationError] = useState(false);
  const [config, setConfig] = useGlobalState('config');
  const [source, setSource] = useGlobalState('appSources');
  const [membershipStatus, setMembershipStatus] = useState<MembershipStatus>(MembershipStatus.Valid);
  const [activeTabs, setActiveTabs] = useState([
    {
      id: '1',
      value: ProfileTabEnum.MY_ACCOUNT,
      content: <React.Fragment />,
    },
    // {
    //   id: '2',
    //   value: ProfileTabEnum.PAY_BILLS,
    //   content: <React.Fragment />,
    // },
    {
      id: '3',
      value: ProfileTabEnum.PROFILE,
      content: <React.Fragment />,
    },
  ]);

  const [customerDetails, setCustomerDetails] = useState<{ data: CustomerDetails; status: ApiStatus }>(
    initialCustomerDetailData
  );

  const [loggedIn, setLoggedIn] = useState(false);

  const userState = useSelector<RootState, UserState>((state) => state.userState);
  const modalState = useSelector<RootState, ModalState>((state) => state.modalState as ModalState);
  const analyticsState = useSelector<RootState, AnalyticsState>((state) => state.analyticsState as AnalyticsState);

  const initialTab = useRef(activeTabs[0].id);

  const onSelectTab = (selectedTab) => {
    const tabData = activeTabs;
    const tabId: number = parseInt(selectedTab.id);
    switch (tabId) {
      case 1:
        tagMyAccountView(analyticsState.model);
        dispatch(setActiveTab(tabData[tabId - 1]));
        break;
      case 2:
        tagPayBillView(analyticsState.model);
        dispatch(setActiveTab(tabData[tabId - 1]));
        break;
      case 3:
        tagProfileView(analyticsState.model);
        dispatch(setActiveTab(tabData[tabId - 1]));
        break;
      case 0:
      default:
        tagApplicationPageLoad(analyticsState.model);
    }
  };

  useEffect(() => {
    if (config) {
      const tempTabs = [...activeTabs];
      tempTabs[0].value = config.Tab1 || ProfileTabEnum.MY_ACCOUNT;
      // const tab2Label = config.Tab2 || ProfileTabEnum.PAY_BILLS;
      tempTabs[1].value = config.Tab3 || ProfileTabEnum.PROFILE;
      tempTabs[0].content = (
        <HomeTabContent
          customerDetails={customerDetails.data}
          config={config}
          customerDetailsStatus={customerDetails.status}
          showNotificationError={notificationError}
        />
      );
      const profileTabProps = {
        manageAutoPayURL: config.manageAutoPayURL,
        manageContactInfoURL: config.MembershipURLs?.membershipChangeContactInfoURL,
        managePasswordURL: config.managePasswordURL,
        managePaymentAccountsURL: config.managePaymentAccountsURL,
        manageCommPrefsURL: config.manageCommPrefsURL,
        logoutURL: config.logoutURL,
        homeURL: config.HomeURL,
        manageUsernameConfig: config.ManageUsernameEnabled,
        clubCode,
        ManageAddressChange: config.ManageAddressChangeEnabled,
        manageAddressURL: config.MembershipURLs?.ManageAddressChangeURL,
        config,
      } as ProfileTabProps;
      // tempTabs[1].content = (
      //   <PayBillsTabContent
      //     config={config}
      //     customerDetailsStatus={customerDetails.status}
      //     analyticsStatus={analyticsStatus}
      //   />
      // );
      tempTabs[1].content = <ProfileTabContent {...profileTabProps} />;
      setActiveTabs(tempTabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsState, config, clubCode, customerDetails]);

  const setTaggging = (analyticsResp: TaggingModel) => {
    const customerData: Customer = GetSourceParam(SourceParam.customerData);
    const customerApiError = customerData.apiStatus?.customer === 'rejected';

    const productsData: Customer = GetSourceParam(SourceParam.productsData);
    const billingApiError = productsData.apiStatus?.billing === 'rejected';
    const policyApiError = productsData.apiStatus?.policy === 'rejected';

    let error = '';
    const message = 'my account:manage my account-unable to retrieve';

    if (Object.keys(productsData).length === 0 || (billingApiError && policyApiError)) {
      error = `${message} billing/product information`;
    } else if (billingApiError) {
      error = `${message} billing information`;
    } else if (policyApiError) {
      error = `${message} product information`;
    } else if (Object.keys(customerData).length === 0 || customerApiError) {
      error = `${message} customer information`;
    }

    analyticsResp.error = error;
    tagApplicationPageLoad(analyticsResp);
  };

  useEffect(() => {
    const loadCustomer = async () => {
      if (config) {
        let token;
        if (config.identityUsePkce) {
          const areaQueryParam = 'area';
          const areaStorageValue = sessionStorage.getItem(areaQueryParam);
          const searchParams: { query: string; value: string }[] = [];
          if (areaStorageValue) {
            searchParams.push({ query: areaQueryParam, value: areaStorageValue });
          }

          const aceIdentity = new AceIdentity({
            env: config.identityEnv,
            redirectUri: config.identityRedirectUri,
            loginUrl: config.identityLoginUrl,
            searchParams,
          });
          await aceIdentity.ready();
          const tokens = await aceIdentity.tokens();
          token = tokens?.accessToken;
        }

        const loginUrl = WebUtils.getLoginUrl(config);
        // for our clubs login url is always exists, otherwis - bailout
        if (!loginUrl) return;

        sessionStorage.setItem('loginUrl', loginUrl);

        const responseFromAuthenticationLogin = new URLSearchParams(
          process.env.REACT_APP_SOURCES === 'stubs' ? authenticateFixture.locationSearch : window.location.search
        );

        const tokenFromQueryParam = responseFromAuthenticationLogin.get('token');
        const tabOption = responseFromAuthenticationLogin.get('tabOption');

        const cookies = new Cookies();

        if (tokenFromQueryParam) {
          token = tokenFromQueryParam;
        }

        if (tabOption) {
          const initialActiveTab = activeTabs.find((tab) => tab.value.toUpperCase() === tabOption.toUpperCase());
          if (initialActiveTab) {
            initialTab.current = initialActiveTab.id;
          }
        }

        if (tokenFromQueryParam) {
          responseFromAuthenticationLogin.delete('token');
          responseFromAuthenticationLogin.delete('cid');
          responseFromAuthenticationLogin.delete('ts');
          const domain = new URL(window.location.href);

          const port = domain.port ? `:${domain.port}` : '';
          const queryParams = responseFromAuthenticationLogin.toString()
            ? `?${responseFromAuthenticationLogin.toString()}`
            : '';
          const newState = {
            title: document.title,
            url: `${domain.protocol}//${domain.hostname}${port}${queryParams}`,
          };

          window.history.replaceState(newState, newState.title, newState.url);
        }

        if (token) {
          //set version
          const version = process.env.REACT_APP_VERSION ?? '';
          localStorage.setItem('aceVersion', version);

          //TODO: check for existing sessionId

          const sessionId = WebUtils.getSessionId();
          httpInstance.defaults.headers['x-ace-session-id'] = sessionId;

          const apiKey = 'NtVBQzVdIRaa4QCQ5EdCc7jB5kOzoO';
          LogCannon.init(apiKey, {
            team: TeamValue.Enterprise,
            appUrl: window.location.toString().split('?')[0],
            workload: WorkloadValue.Enterprise,
            component: 'myaccount',
            runtime: 'nodejs',
            runtimeVersion: process.version,
          });

          httpInstance.defaults.headers.authorization = `Bearer ${token}`;
          const decodedPayload = Object.assign(
            { clubCode: '', customerId: '', jti: '', membershipStatus: '', memberNumber: '' },
            jwtDecode(token)
          );
          cookies.set('aceu', decodedPayload.customerId);
          sessionStorage.setItem('clubCode', decodedPayload.clubCode);
          setClubCode(decodedPayload.clubCode as ClubCodes);
          dispatch(
            setUserState({
              sessionId,
              customerId: decodedPayload.customerId,
              clubCode: decodedPayload.clubCode,
            } as UserState)
          );
          const { aceMeta } = WebUtils.getMetadata({
            //TODO: refactor
            customerId: decodedPayload.customerId,
            membershipNumber: decodedPayload.memberNumber,
            clubCode: decodedPayload.clubCode,
          });
          const correlationIdConfig = uuid();
          const configRequest = { aceMeta: { ...aceMeta, correlationId: correlationIdConfig } };
          const configTimer = new Timer('configTimer', sessionId);
          const configStart = configTimer.startTimer().start;

          try {
            //TODO: make aceMeta a global variable
            LogCannon.blast('config - start', {
              aceMeta: { ...aceMeta, correlationId: correlationIdConfig },
              start: configStart,
            });

            //TODO: add cache to this call (perhaps using react-query) to avoid duplicates call between app.tsx and appContainer.tsx
            const configResponse = await configService(configRequest);
            const { end, responseTime } = configTimer.endTimer();
            LogCannon.blast('config - end', {
              aceMeta: { ...aceMeta, ...configResponse?.aceMeta },
              metrics: { start: configStart, end, responseTime },
              status: 'success',
            });
            setConfig(configResponse.data);
            SetSourceParam(SourceParam.config, configResponse.data);
            const endPoints = configResponse.data?.ApiEndpoints;
            if (endPoints) {
              setSource(appSources(endPoints));
            }
            sessionStorage.setItem('loginUrl', WebUtils.getLoginUrl(config));

            // V3 considerations from the config and modify session storage
            // const apiVersionV3 = configResponse.data?.ApiVersionV3 ?? [];
            // const hasClubCode: boolean = apiVersionV3.includes(decodedPayload.clubCode) || apiVersionV3.includes('ALL');
            // const apiVersionNumberV3 = hasClubCode ? 'v3' : 'v1';

            //sessionStorage.setItem('apiVersion', apiVersionNumberV3);

            // Deprecated code
            // let storedVersion = sessionStorage.getItem('apiVersion');
            // if (!storedVersion) {
            //   sessionStorage.setItem('apiVersion', apiVersionNumberV3);
            // }

          } catch (error: any) {
            const { end, responseTime } = configTimer.endTimer();
            LogCannon.error('config - end', {
              aceMeta,
              metrics: { start: configStart, end, responseTime },
              status: 'fail',
              error,
            });
            if (!networkBrowserErrors.includes(error.ComponentErrorCode)) {
              dispatch(setErrorState({ model: error as ServiceError }));
            }
          }

          const apiVersionFromSource = 'v3';
          SetSourceParam(SourceParam.apiVersion, apiVersionFromSource);

          // deprecated code
          // const v3 = sessionStorage.getItem('apiVersion');
          // if (v3) {
          //   SetSourceParam(SourceParam.apiVersion, v3);
          // } else {
          //   SetSourceParam(SourceParam.apiVersion, 'v1');
          // }
          // const apiVersionFromSource = GetSourceParam(SourceParam.apiVersion);

          const correlationIdCustomer = uuid();
          const correlationIdProducts = uuid();
          const requestCustomer = {
            aceMeta: { ...aceMeta, correlationId: correlationIdCustomer, apiVersion: apiVersionFromSource },
          };
          const requestProducts = { aceMeta: { ...aceMeta, correlationId: correlationIdProducts } };

          const cciTimer = new Timer('cciTimer', sessionId);
          const { start: startCCI } = cciTimer.startTimer();

          try {
            LogCannon.blast('customer - start', {
              aceMeta: { ...aceMeta, correlationId: correlationIdCustomer },
              start: startCCI,
            });

            const cciCalls = [source.customer(requestCustomer)];

            if (apiVersionFromSource === 'v3') {
              LogCannon.blast('products - start', {
                aceMeta: { ...aceMeta, correlationId: correlationIdProducts },
                start: startCCI,
              });

              cciCalls.push(source.products(requestProducts));
            }

            const [customerData, productsData] = await Promise.allSettled(cciCalls);

            const { end, responseTime } = cciTimer.endTimer();
            const metrics = {
              start: startCCI,
              end,
              responseTime,
            };

            //TODO: send aws and backend regions in api response so they can be captured in logs

            const processError = (data: PromiseSettledResult<any>, apiName: string, key: SourceParam, correlationId: string) => {
              if (data.status === 'fulfilled') {

                const dv:any = data.value;
                let cnt=0, rate=0;
                Object.keys(dv?.apiStatus).forEach( (k) => {
                  cnt ++;
                  rate += ((dv.apiStatus[k] === 'fulfilled') ? 1 : 0);
                });

                LogCannon.blast(`${apiName} - end`, {
                  aceMeta: { ...aceMeta, ...data?.value?.aceMeta },
                  metrics,
                  status: 'success',
                  rate: (cnt) ? ~~(100*rate/cnt) : 0,
                  api: apiName
                });
                SetSourceParam(key, data.value);
              } else {
                LogCannon.error(`${apiName} - end`, {
                  aceMeta: { ...aceMeta, correlationId },
                  status: 'fail',
                  error: data.reason,
                  metrics,
                  rate: 0,
                  api: apiName
                });
                setNotificationError(true);
              }
            };

            processError(customerData, 'customer', SourceParam.customerData, correlationIdCustomer );

            // if (customerData.status === 'fulfilled') {
            //   LogCannon.blast('customer - end', {
            //     aceMeta: { ...aceMeta, ...customerData?.value?.aceMeta },
            //     metrics,
            //     status: 'success',
            //   });
            //   SetSourceParam(SourceParam.customerData, customerData.value);
            // } else {
            //   LogCannon.error('customer - end', {
            //     aceMeta: { ...aceMeta, correlationId: correlationIdCustomer },
            //     status: 'fail',
            //     error: customerData.reason,
            //     metrics,
            //   });
            //   setNotificationError(true);
            // }

            if (productsData) {
              processError(productsData, 'products', SourceParam.productsData, correlationIdProducts);

              // if (productsData.status === 'fulfilled') {
              //   LogCannon.blast('products - end', {
              //     aceMeta: { ...aceMeta, ...productsData?.value?.aceMeta },
              //     metrics,
              //     status: 'success',
              //   });
              //   SetSourceParam(SourceParam.productsData, productsData.value);
              // } else {
              //   LogCannon.error('products - end', {
              //     aceMeta: { ...aceMeta, correlationId: correlationIdProducts },
              //     metrics,
              //     status: 'fail',
              //     error: productsData.reason,
              //   });
              //   setNotificationError(true);
              // }
            }
          } catch (error: any) {
            const { end, responseTime } = cciTimer.endTimer();
            LogCannon.error('error calling cci', {
              aceMeta,
              metrics: { start: startCCI, end: end, responseTime },
              status: 'fail',
              error,
            });
          }

          const customerDetailsTimer = new Timer('customerDetailsWeb', sessionId);
          const start = customerDetailsTimer.startTimer();

          source
            .customerDetails(requestCustomer)
            .then((customerDetailResp: CustomerDetailsResponseApp) => {
              const { customerDetails } = customerDetailResp?.data;
              const membershipNumber: string =
                customerDetails?.products?.find((p) => p.type === ProductType.MEMBERSHIP)?.productId || '';

              WebUtils.logMetrics(
                'customerDetailsWeb',
                WebUtils.constructLogData(
                  { str: start, end: customerDetailsTimer.endTimer(), status: 'success' },
                  decodedPayload?.customerId,
                  membershipNumber
                ),
                aceMeta
              );

              setCustomerDetails({
                data: customerDetails,
                status: ApiStatus.SUCCESS,
              });

              // queue macrotask to hide spinner
              setTimeout(() => setLoggedIn(true), 100);
              dispatch(
                setUserState({
                  ...customerDetails.user,
                  membershipNumber,
                  sessionId,
                  pageLoadSuccessful: true,
                  products: customerDetails.products,
                  state:
                    (customerDetails.person &&
                      customerDetails.person.residenceAddress &&
                      customerDetails.person.residenceAddress.address &&
                      customerDetails.person.residenceAddress.address.state) ||
                    '',
                } as UserState)
              );
              const mda = createAnalytics({ customerDetails });
              cookies.set('MDA', mda);

              // load utag libraries after setting MDA cookie
              utility.loadExternalJavascriptLibraries('//tms.ace.aaa.com/ace/prod/utag.sync.js');
              loadUtagJs();

              const analyticsResp: TaggingModel = taggingMapper({ customerDetails });
              analyticsResp.clubcode = clubCode;
              analyticsResp.sessionId = sessionId;
              dispatch(setAnalyticsState({ model: analyticsResp }));

              const membershipStatus = decodedPayload?.membershipStatus?.trim()?.toLowerCase();
              if (membershipStatus === MembershipStatus.Cancelled) {
                const error: any = { message: 'Membership cancelled' };
                WebUtils.logWeb({
                  message: error.message,
                  level: WebLogLevel.error,
                  data: WebUtils.constructLogData({ error }, decodedPayload?.customerId, userState?.membershipNumber),
                  aceMeta,
                });
                setMembershipStatus(MembershipStatus.Cancelled);
              } else {
                // at this point setAnalyticsState is not finished until next reload so it need to use raw value from analyticsResp
                tabOption?.toLowerCase() === 'profile' ? tagProfilePageLoad(analyticsResp) : setTaggging(analyticsResp);
              }
            })

            .catch((error: ServiceError) => {
              WebUtils.logMetrics(
                'customerDetailsWeb',
                WebUtils.constructLogData(
                  { str: start, end: customerDetailsTimer.endTimer(), status: 'fail' },
                  decodedPayload?.customerId,
                  userState?.membershipNumber
                ),
                aceMeta,
                WebLogLevel.error
              );
              if (!networkBrowserErrors.includes(error.ComponentErrorCode)) {
                dispatch(setErrorState({ model: error } as ErrorState));
              }
              WebUtils.logWeb({
                message: 'Failed to load customerDetails',
                level: WebLogLevel.error,
                data: WebUtils.constructLogData({ error }, decodedPayload?.customerId, userState?.membershipNumber),
                aceMeta: requestCustomer.aceMeta,
              });
            });
        } else {
          // only redirect for non-pkce flow
          // as for pkce, the redirect is automatically handled by ace-identity lib
          if (!config.identityUsePkce) {
            WebUtils.externalLinkHandler(loginUrl, false, true).catch(() => { });
          }
        }
      }
    };

    loadCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loggedIn || !config) {
    return (
      <ACEPrimaryTheme>
        <LayoutContainer>
          <div className="spinnerContainer">
            <Spinner id="appLoginSpinner" variant="indeterminate" />
          </div>
          <ErrorComp appConfig={config as ConfigResponseApp} />
        </LayoutContainer>
      </ACEPrimaryTheme>
    );
  }

  const handleCancelledPolicyModalClick = (hasLapseAccidents: boolean) => {
    const extraFields: FormPostKeyValue[] = modalState && modalState.extraFields ? modalState.extraFields : [];
    const payBillUrl = modalState && modalState.payBillUrl ? modalState.payBillUrl : '';
    let formData;
    if (Array.isArray(extraFields)) {
      const find = extraFields.find((k) => k.key === 'hasLapseAccidents');
      if (find) find.value = hasLapseAccidents;
      formData = extraFields;
    }
    WebUtils.FormPost(formData, payBillUrl, userState?.customerId, userState?.membershipNumber);
    dispatch(setModalState({ cancelledPolicyModal: false } as ModalState));
  };
  const handleHeaderLinkClick = (ev) => {
    ev.preventDefault();
    const newPage = ev.detail.target === '_blank';
    if (ev.detail.title === 'log out') {
      WebUtils.handleLogout(config.logoutURL, config.HomeURL, userState.customerId, userState.membershipNumber);
      return;
    }
    WebUtils.externalLinkHandler(ev.detail.url, newPage).catch(console.error);
  };

  return (
    <ACEPrimaryTheme>
      <AceHeader
        disableMyAccountLink={true}
        onLinkClicked={handleHeaderLinkClick}
        firstName={userState?.customerName || ''}
        isLoggedIn={true}
        showMobileMenu={config.showMobileMenu}
      ></AceHeader>
      <main style={{ flex: '1 0 auto' }}>
        <LayoutContainer>
          {membershipStatus === MembershipStatus.Valid ? (
            <>
              <Welcome message={customerDetails.data.welcomeMessage} />
              <TabGroup id="nav-tabs" tabs={activeTabs} onSelect={onSelectTab} value={initialTab.current} />
              <ErrorComp appConfig={config} />
            </>
          ) : (
            <Error error={{} as any} type={ErrorTypes.MembershipCancelled} clubCode={clubCode} />
          )}
        </LayoutContainer>
      </main>
      <AceFooter club={clubCode}></AceFooter>
      <SecurityModal
        onClick={() =>
          dispatch(
            setModalState({
              ...modalState,
              securityModal: false,
            } as ModalState)
          )
        }
        openModal={modalState.securityModal || false}
        description={modalState.securityModalDescription}
        actionMessage={modalState.securityModalMessage}
      />
      <CancelledPolicyModal
        openModal={Boolean(modalState.cancelledPolicyModal)}
        actionMessage={modalState.cancelledPolicyMessage}
        description={modalState.cancelledPolicyDescription}
        taggingSource={modalState.taggingSource as TaggingSource}
        onClick={(yesNo) => handleCancelledPolicyModalClick(yesNo)}
      />
    </ACEPrimaryTheme>
  );
};

export default App;
