import { createGlobalState } from 'react-hooks-global-state';
import { ErrorTypes } from './models/errorTypes';
import { ConfigResponseApp, MaintenanceStatusModel } from 'shared';
import { appSources, Sources } from 'remotes';

export type State = {
  ClubInfo: string | undefined;
  loading: boolean | undefined;
  config: ConfigResponseApp | undefined;
  maintenanceStatus: MaintenanceStatusModel | undefined;
  ErrorType: ErrorTypes | undefined;
  appSources: Sources;
};

export const createInitialState = (): State => ({
  ClubInfo: undefined,
  loading: false,
  config: undefined,
  maintenanceStatus: undefined,
  ErrorType: undefined,
  appSources: appSources(),
});

export const { useGlobalState, setGlobalState } = createGlobalState<State>(createInitialState());
