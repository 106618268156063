import * as actions from '../Actions/cardcontainer.actions';
import { AwarenessCard, Notification } from 'shared';

export interface CardContainerState {
  contentLoaded: boolean;
  billingNotificationsLoaded: boolean;
  allCards: AwarenessCard[] | Notification[];
}

export const initialState = {
  contentLoaded: false,
  billingNotificationsLoaded: false,
  allCards: [],
};

export const cardContainerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CARDS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
