import React from 'react';
import Redux, { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store/rootReducer';
import { setErrorState } from '../../../redux/store/Actions/error.actions';
import { ServiceError, Timer, WebUtils } from 'utils';
import {
  CardCodeEnum,
  AwarenessCardOption,
  UpdateUserPrefRequestApp,
  UpdateUserPrefResponseApp,
  AwarenessCard as AwarenessCardModel,
  WebLogLevel,
  ApiRefIdSuffix,
} from 'shared';
import AwarenessCard from '../AwarenessCard';
import { Button } from '@clublabs/ace-component-library';
import { appSources } from 'remotes';
import { ErrorState } from '../../../redux/store/Reducers/error.reducer';
import Cookies from 'universal-cookie';
import { setCardContainerState } from '../../../redux/store/Actions/cardcontainer.actions';
import { CardContainerState } from '../../../redux/store/Reducers/cardcontainer.reducer';
import { tagCreditCard, tagPMIDAwareness } from 'Components/Common/TaggingHelper';

interface RequiredProps {
  cardCode: CardCodeEnum;
  id: string;
  title: any;
  body: any;
  buttonText: string;
  dismissable: boolean;
  ctaURL: string;
  onClose: (card: { code: CardCodeEnum; prodId: string }) => void;
}
interface OptionalProps {
  productId: string;
  options: AwarenessCardOption[];
}

const mapStateToProps = (rootState: RootState) => {
  return {
    userState: rootState.userState,
    errors: rootState.errorState,
    cardContainerState: rootState.cardContainerState,
    analytics: rootState.analyticsState,
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return bindActionCreators(
    {
      setErrorState,
      setCardContainerState,
    },
    dispatch
  );
};

export type PaperlessCardProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps &
  Partial<OptionalProps>;

export interface PaperlessCardState {
  isHidden: boolean;
}

export class PaperlessCard extends React.Component<PaperlessCardProps, PaperlessCardState> {
  constructor(props: PaperlessCardProps) {
    super(props);
    this.state = {
      isHidden: false,
    };
  }

  public componentDidMount = () => {};

  hide = (value) => {
    this.setState({ isHidden: value });
  };

  handleOptionsClick = (option) => {
    const { sessionId, aceMeta } = WebUtils.getMetadata();
    const request: UpdateUserPrefRequestApp = {
      aceMeta,
      data: {
        productId: this.props.productId,
        prefCode: this.props.cardCode.toString(),
        prefSelection: option.key,
      },
    };
    const updateUserPrefTimer = new Timer('updateUserPrefWeb - paperlessCard', sessionId);
    const start = updateUserPrefTimer.startTimer();
    appSources()
      .updateUserPreferece(request)
      .then((response: UpdateUserPrefResponseApp) => {
        WebUtils.logMetrics(
          'updateUserPrefWeb',
          WebUtils.constructLogData(
            { str: start, end: updateUserPrefTimer.endTimer(), status: 'success' },
            this.props.userState?.customerId,
            this.props.userState?.membershipNumber,
          ),
          aceMeta
        );
        if (response) {
          //remove from allCards redux state
          const allCards =
            this.props.cardContainerState && (this.props.cardContainerState.allCards as AwarenessCardModel[]);
          this.props.setCardContainerState &&
            this.props.setCardContainerState({
              ...this.props.cardContainerState,
              allCards: allCards && allCards.filter((c) => c.code !== this.props.cardCode),
            } as CardContainerState);
          this.hide(true);
          this.props.onClose({ code: this.props.cardCode, prodId: this.props.productId || '' });
        }
      })
      .catch((error: ServiceError) => {
        WebUtils.logMetrics(
          'updateUserPrefWeb - paperlessCard',
          WebUtils.constructLogData(
            { str: start, end: updateUserPrefTimer.endTimer(), status: 'fail' },
            this.props.userState?.customerId,
            this.props.userState?.membershipNumber,
          ),
          aceMeta,
          WebLogLevel.error
        );
        if (error && this.props.setErrorState) {
          error.SessionId = `${sessionId}-${ApiRefIdSuffix.AwarenessCards}`;
          this.props.setErrorState({ model: error } as ErrorState);
          WebUtils.logWeb({
            message: 'Failed update user preferences for Paperless awareness card',
            level: WebLogLevel.error,
            data: WebUtils.constructLogData({ error }, this.props?.userState?.customerId, this.props?.userState?.membershipNumber),
            aceMeta,
          });
        }
      });
  };

  mapHeaderOptions = (options) => {
    return options.map((option) => {
      return { onClick: this.handleOptionsClick, ...option };
    });
  };

  enrollNowClick = () => {
    let paperlessURL = this.props.ctaURL;
    const analytics = this.props.analytics;
    if (this.props.cardCode === CardCodeEnum.ENROLL_PAPERLESS) {
      const cookies = new Cookies();
      const token = cookies.get('aceut');
      if (token) {
        paperlessURL += `?returnUrl=${encodeURIComponent(window.location.href)}&token=${token}`;
      }
    }
    if (this.props.cardCode === CardCodeEnum.CREDIT_CARD) {
      if (analytics) {
        tagCreditCard(analytics.model);
      }
    }
    if (this.props.cardCode === CardCodeEnum.PMID) {
      if (analytics) {
        tagPMIDAwareness(analytics.model);
      }
    }
    WebUtils.externalLinkHandler(paperlessURL, false, false, this.props?.userState?.customerId, this.props?.userState?.membershipNumber).catch(() => {});
  };

  public render() {
    return (
      !this.state.isHidden && (
        <AwarenessCard
          id={this.props.id}
          key={`AwarenessCard-${this.props.id}`}
          title={this.props.title}
          body={this.props.body}
          dismissable={this.props.dismissable}
          cardHeaderOptions={this.mapHeaderOptions(this.props.options)}
          cardActions={[
            <Button
              id="awareness-card1-cta"
              onClick={this.enrollNowClick}
              key={`CardAction-Card-${this.props.id}-CardAction-EnrollNow`}
            >
              {this.props.buttonText}
            </Button>,
          ]}
          cardCode={this.props.cardCode}
        ></AwarenessCard>
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaperlessCard);
