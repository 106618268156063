import { CiBullhorn } from 'react-icons/ci'
import { Link } from 'react-router-dom';

export interface MembershipUpgradeHintProps {
  membershipType: string;
  effectiveDate: string;
  membershipUpgradeURL: string;
  sevenDayClickHandler?: (url: any) => void;
}

export const MembershipUpgradeHint: React.FunctionComponent<MembershipUpgradeHintProps> = ({
  membershipType,
  effectiveDate,
  membershipUpgradeURL,
  sevenDayClickHandler,
}: MembershipUpgradeHintProps) => {

  const clickHandler = (event) => {
    event.stopPropagation();
    sevenDayClickHandler?.(membershipUpgradeURL)
  }

  return (
    <div
      style={{
        padding: '15px',
        margin: '10px 0 25px 0',
        backgroundColor: '#FFF3D6',
        fontSize: '.9em',
        border: 'none',
        textAlign: 'center',
        borderRadius: '10px',
        display: 'flex',
      }}
      data-testid="membership-hint"
    >
      <div style={{ paddingRight: '5px' }}>
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: 'white',
            height: '35px',
            width: '35px',
          }}
        >
          <CiBullhorn color='#A85D00' style={{ height: '35px', width: '25px' }} />
        </div>
      </div>
      <div style={{ paddingTop: '3px', textAlign: 'justify' }}>
        <p>
          Your {membershipType} roadside benefits will be effective {effectiveDate}. Other benefits may be effective
          immediately.
          <span> </span>
          <span style={{color:'blue', textDecoration: 'underline'}}onClick={(e)=>clickHandler(e)}> 
            See details
          </span>
        </p>
      </div>
    </div>
  );
};
