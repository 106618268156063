import React, { useEffect, useState } from 'react';
import cx from 'clsx';
import { withStyles, Card, CardHeader } from '@material-ui/core';
import Redux, { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store/rootReducer';
import { setCardContainerState } from '../../redux/store/Actions/cardcontainer.actions';
import { setErrorState } from '../../redux/store/Actions/error.actions';
import { CardContainerState } from '../../redux/store/Reducers/cardcontainer.reducer';
import { ExpansionPanel, Spinner, IconType, ACEIcon, Subheadline, Subtitle } from '@clublabs/ace-component-library';
import {
  Notification,
  AwarenessCard,
  NotificationsResponseApp,
  CardCodeEnum,
  NotificationType,
  ConfigResponseApp,
  ClubCodes,
  State,
  ProductType,
  WebLogLevel,
  ApiStatus,
  ApiRefIdSuffix,
  AwarenessCardResponseApp,
} from 'shared';
import { Timer, ServiceError, WebUtils } from 'utils';
import { ErrorState } from '../../redux/store/Reducers/error.reducer';
import CardFactory from '../AwarenessCard/CardFactory';
import ACGCard from '../NotificationCard/ACGCard';
import { useGlobalState } from 'shared/state';
import { cardContainerStyles } from './CardsContainerStyles';

interface RequiredProps {
  id: string;
  cardTypes: CardCodeEnum[];
  config: ConfigResponseApp;
  state: string | State;
  customerDetailsStatus: ApiStatus;
}

interface OptionalProps {
  classes?: any;
  className?: string;
  paymentNotifications?: boolean;
  showHeader?: boolean;
  membershipExpDate?: string;
  membershipNumber?: string;
}

const mapStateToProps = (rootState: RootState) => {
  return {
    contentLoaded: Boolean(rootState?.cardContainerState?.contentLoaded),
    billingNotificationsLoaded: Boolean(rootState?.cardContainerState?.billingNotificationsLoaded),
    allCards: rootState?.cardContainerState?.allCards,
    customerId: rootState?.userState?.customerId,
    state: rootState?.userState?.state,
    clubCode: rootState?.userState?.clubCode,
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return bindActionCreators(
    {
      setCardContainerState,
      setErrorState,
    },
    dispatch
  );
};

type CardContainerProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps &
  Partial<OptionalProps>;

export const CardsContainer: React.FunctionComponent<CardContainerProps> = (props) => {
  const [billingNotifications, setBillingNotifications] = useState<Notification[]>([]);
  const [awarenessNotifications, setAwarenessNotifications] = useState<AwarenessCard[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [showACGNotification, setACGNotification] = useState<boolean>(false);
  const [source] = useGlobalState('appSources');
  const [cards, setCards] = useState(false);

  let allCards;
  let isComponentMounted;

  const cardReportError = (aceMeta: any, title: string, message: string, reason: any, start: any, end: any) => {
    WebUtils.logMetrics(
      title, // 'awarenessCardWeb'
      WebUtils.constructLogData(
        { str: start, end, status: 'fail' },
        props.customerId,
        props.membershipNumber
      ),
      aceMeta,
      WebLogLevel.error
    );

    WebUtils.logWeb({
      message, // 'Failed to load awareness cards',
      level: WebLogLevel.error,
      data: WebUtils.constructLogData({ error: reason }, props.customerId, props.membershipNumber),
      aceMeta,
    });

  };

  useEffect(() => {
    isComponentMounted = true;
    // billing notifications api call
    setACGNotification(Boolean(WebUtils.isECNonPA(props.clubCode as ClubCodes, props.state)));

    if (!cards && props.customerDetailsStatus === ApiStatus.SUCCESS) {
      // console.log('>>> DEBUG: verify notifications & awareness cards. props:', JSON.stringify(props));

      const { sessionId, aceMeta } = WebUtils.getMetadata();

      const notificationsRequest = { aceMeta };
      const awarenessCardsRequest = { aceMeta };

      /* OLD
      const notificationsTimer = new Timer('notificationsWeb', sessionId);
      const notificationsStart = notificationsTimer.startTimer();

      source
        .notifications(notificationsRequest)
        .then((notificationResp: NotificationsResponseApp) => {
          WebUtils.logMetrics(
            'notificationsWeb',
            WebUtils.constructLogData(
              { str: notificationsStart, end: notificationsTimer.endTimer(), status: 'success' },
              props.customerId,
              props.membershipNumber
            ),
            aceMeta
          );
          const { notifications } = notificationResp.data;
          const filteredBillingNotifications = notifications.filter(
            (notification) => notification.type !== NotificationType.BILLING_INFO
          );
          const billingNotificationsNoIQ = filteredBillingNotifications.filter(
            (notification) => notification.type !== NotificationType.INSURANCE_QUESTIONNAIRE
          );

          // PayBill tab, hide IQ notifications
          if (isComponentMounted) {
            if (props.paymentNotifications) {
              setBillingNotifications(billingNotificationsNoIQ);
            } else {
              setBillingNotifications(filteredBillingNotifications);
            }

            if (props.setCardContainerState && filteredBillingNotifications.length > 0 && props.paymentNotifications) {
              props.setCardContainerState({ billingNotificationsLoaded: true } as CardContainerState);
            }
          }
        })
        .catch((error: ServiceError) => {
          WebUtils.logMetrics(
            'notificationsWeb',
            WebUtils.constructLogData(
              { str: notificationsStart, end: notificationsTimer.endTimer(), status: 'fail' },
              props.customerId,
              props.membershipNumber
            ),
            aceMeta,
            WebLogLevel.error
          );
          if (props.setErrorState) {
            error.SessionId = `${sessionId}-${ApiRefIdSuffix.Notifications}`;
            props.setErrorState({ model: error } as ErrorState);
            WebUtils.logWeb({
              message: 'Failed to load notification cards',
              level: WebLogLevel.error,
              data: WebUtils.constructLogData({ error }, props.customerId, props.membershipNumber),
              aceMeta,
            });
          }
        });

      const awarenessCardsTimer = new Timer('awarenessCardWeb', sessionId);
      const awarenessCardsStart = awarenessCardsTimer.startTimer();

      source
        .awarenessCards(awarenessCardsRequest)
        .then((response: AwarenessCardResponseApp) => {
          WebUtils.logMetrics(
            'awarenessCardWeb',
            WebUtils.constructLogData(
              { str: awarenessCardsStart, end: awarenessCardsTimer.endTimer(), status: 'success' },
              props.customerId,
              props.membershipNumber
            ),
            aceMeta
          );
          const { awarenessCards } = response.data;
          if (isComponentMounted) {
            if (!props.paymentNotifications) {
              setAwarenessNotifications(awarenessCards);
            }
            if (props.setCardContainerState && awarenessCards.length > 0 && props.paymentNotifications) {
              props.setCardContainerState({ contentLoaded: true } as CardContainerState);
            }
            setIsFetching(false);
          }
        })
        .catch((error: ServiceError) => {
          WebUtils.logMetrics(
            'awarenessCardWeb',
            WebUtils.constructLogData(
              { str: awarenessCardsStart, end: awarenessCardsTimer.endTimer(), status: 'fail' },
              props.customerId,
              props.membershipNumber
            ),
            aceMeta,
            WebLogLevel.error
          );
          setIsFetching(false);
          WebUtils.logWeb({
            message: 'Failed to load awareness cards',
            level: WebLogLevel.error,
            data: WebUtils.constructLogData({ error }, props.customerId, props.membershipNumber),
            aceMeta,
          });
        });
      */

      /* NEW */
      if (isComponentMounted) {

        setIsFetching(true);
        setCards(true);

        const allTimer = new Timer('cardsWeb', sessionId);
        const startTimer = allTimer.startTimer();

        const awarenessRequest = source.awarenessCards(awarenessCardsRequest);
        const notificationRequest = source.notifications(notificationsRequest);

        Promise.allSettled([
          awarenessRequest,
          notificationRequest
        ]).then((results) => {

          const endTimer = allTimer.endTimer();

          if ((results[0] as PromiseFulfilledResult<AwarenessCardResponseApp>).value) {
            const { awarenessCards } = (results[0] as PromiseFulfilledResult<AwarenessCardResponseApp>).value.data;
            if (!props.paymentNotifications) {
              setAwarenessNotifications(awarenessCards);
            }
            if (props.setCardContainerState && awarenessCards.length > 0 && props.paymentNotifications) {
              props.setCardContainerState({ contentLoaded: true } as CardContainerState);
            }
            WebUtils.logMetrics(
              'awarenessCardWeb',
              WebUtils.constructLogData(
                { str: startTimer, end: endTimer, status: 'success' },
                props.customerId,
                props.membershipNumber
              ),
              aceMeta
            );
  
          } else {
            cardReportError(aceMeta, 'awarenessCardWeb', 'Failed to load awareness cards', (results[1] as PromiseRejectedResult).reason, startTimer, endTimer)
          }

          if ((results[1] as PromiseFulfilledResult<NotificationsResponseApp>).value) {
            const { notifications } = (results[1] as PromiseFulfilledResult<NotificationsResponseApp>).value.data;
            const filteredBillingNotifications = notifications.filter(
              (notification) => notification.type !== NotificationType.BILLING_INFO
            );
            const billingNotificationsNoIQ = filteredBillingNotifications.filter(
              (notification) => notification.type !== NotificationType.INSURANCE_QUESTIONNAIRE
            );

            // PayBill tab, hide IQ notifications
            if (props.paymentNotifications) {
              setBillingNotifications(billingNotificationsNoIQ);
            } else {
              setBillingNotifications(filteredBillingNotifications);
            }

            if (props.setCardContainerState && filteredBillingNotifications.length > 0 && props.paymentNotifications) {
              props.setCardContainerState({ billingNotificationsLoaded: true } as CardContainerState);
            }
            WebUtils.logMetrics(
              'notificationsWeb',
              WebUtils.constructLogData(
                { str: startTimer, end: endTimer, status: 'success' },
                props.customerId,
                props.membershipNumber
              ),
              aceMeta
            );
  
          } else {
            cardReportError(aceMeta, 'notificationsWeb', 'Failed to load notification cards', (results[0] as PromiseRejectedResult).reason, startTimer, endTimer)
          }

          setIsFetching(false);
        });
      }

    }

    return () => {
      isComponentMounted = false;
    };
  }, [props, source]);

  //set up allCards, cardVisible and cardsHidden
  if (props.allCards && props.allCards.length > 0 && !props.paymentNotifications) {
    allCards = props.allCards;
  } else allCards = [...billingNotifications, ...awarenessNotifications];

  if (props.setCardContainerState && props.paymentNotifications) {
    props.setCardContainerState({
      contentLoaded: props.contentLoaded,
      billingNotificationsLoaded: props.billingNotificationsLoaded,
      allCards: allCards,
    } as CardContainerState);
  }

  const cardsVisible = showACGNotification
    ? allCards.filter((card) => card.productType === ProductType.MEMBERSHIP)
    : allCards.slice(0, 3);

  const cardsHidden = allCards.length > 3 ? allCards.slice(3, allCards.length) : [];

  const getCards = (cards) => {
    const renderedCards =
      cards &&
      cards.map((card, cardIndex) => {
        return (
          <div key={cardIndex} style={{ visibility: cardIndex > 1 && !isExpanded ? 'hidden' : 'visible' }}>
            <CardFactory
              cardData={card}
              onClose={() => { }}
              config={props.config}
              state={props.state}
              clubCode={props.clubCode as ClubCodes}
              membershipExpDate={props.membershipExpDate}
              membershipNumber={props.membershipNumber}
            />
          </div>
        );
      });
    return renderedCards;
  };

  const expansionPanelItems = [
    {
      header: <Subtitle id="showAll">{isExpanded ? 'Show less' : 'Show all'}</Subtitle>,
      details: getCards(cardsHidden),
    },
  ];

  return (
    <div
      data-quid={`CardsContainer-${props.id}`}
      className={
        !props.paymentNotifications && allCards.length < 1
          ? props.classes.rootEmptyClass
          : cx('CardsContainer', props.classes.root, props.className)
      }
    >
      {isFetching ? <Spinner id="spinner" variant="indeterminate" message="" /> : ''}
      {/* shown only on hometab, has billing notification(IQ, billing due) or has ACG notification */}
      {props.showHeader && (cardsVisible.length > 0 || showACGNotification) ? (
        <div data-quid="notification-headline" className={props.classes.headLine}>
          <div data-quid="notification-icon" className={props.classes.iconContainer}>
            <ACEIcon type={IconType.NOTIFICATIONS} />
          </div>
          <div data-quid="notification-headlinetext" className={props.classes.headLineText}>
            <Subheadline id="notifications">Notifications</Subheadline>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        className={cx('CardsDiv', {
          [props.classes.collapse]: !isExpanded && (cardsVisible.length > 2 || cardsHidden.length > 0),
        })}
      >
        {/* for EastCentral return only ACG card and membership notification card */}
        {showACGNotification ? (
          <>
            <ACGCard acgConfig={props.config && props.config.InsuranceNotificationDetails} />
            {getCards(cardsVisible)}
          </>
        ) : (
          getCards(cardsVisible)
        )}

        {/* {pay bills tab , no cards, no ACG notification} */}
        {props.paymentNotifications && cardsVisible.length === 0 && !showACGNotification ? (
          <Card data-quid="empty-notifications" className={props.classes.notificationCard}>
            <CardHeader
              className={props.classes.cardHeader}
              title="No payments are due at this time."
              subheader={<span data-di-mask>Amount: $00.00</span>}
            ></CardHeader>
          </Card>
        ) : (
          <></>
        )}
        <div className={props.classes.showAll}>
          {cardsVisible.length > 2 || cardsHidden.length > 0 ? (
            <ExpansionPanel
              id="Expansion Panel 2"
              isDefault
              items={expansionPanelItems}
              onChange={() => {
                setExpanded(!isExpanded);
              }}
              switchDirection
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(cardContainerStyles, { index: 0, withTheme: true })(CardsContainer));
